import React from "react";
import { useState, useRef, useEffect } from "react";
import Axios from "axios";

const SingleProfile = ({ open, userid, profiledata }) => {
    // Axios.defaults.withCredentials = true;
    const [id, setId] = useState('');
    const [fullName, setFullName] = useState('');
    const [mobile, setMobile] = useState('');
    const [sitename, setSiteName] = useState('');
    const [sitelink, setSiteLink] = useState('');

    const [usertype, setUserType] = useState('');
    const [regdate, setRegDate] = useState('');
    const [expdate, setExpDate] = useState("");
    const [valid, setValid] = useState(0)
    const [error, setError] = useState("");


    const [isNameValid, setisNameValid] = useState(true);

    const [isSiteNameValid, setisSiteNameValid] = useState(true);
    const [isSiteLinkValid, setisSiteLinkValid] = useState(true);
    const [isMbileValid, setIsMobileValid] = useState(false);
    const [isUserTypeValid, setIsUserTypeValid] = useState(false);

    const [successmsg, setSuccessMsg] = useState("");
    const [errormsg, setErrortMsg] = useState("");

    const [msgTitile, setMsgTitle] = useState("");
    const [msgBody, setMsgBody] = useState("");
    const userId = window.localStorage.getItem("userid");
    const effectRan = useRef(false);

    // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1 = "https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;

    useEffect(() => {

        profiledata.map((item) => {
            if (item.id == userid) {

                setFullName(item.full_name);
                setMobile(item.mobile);
                setSiteName(item.site_name);
                setSiteLink(item.site_link);
                setUserType(item.user_type);
                setRegDate(item.date);
                setExpDate(item.exp_date);
                setValid(item.valid);
            }
        })

    }, [userid]);

    useEffect(() => {

        const timer = setTimeout(() => {
            setSuccessMsg("");
            setError("");

        }, 5000);
        return () => clearTimeout(timer);

    }, [])

    const handleInput = (e, type) => {
        switch (type) {
            case "name":
                setError("");
                setFullName(e.target.value);
                if (e.target.value === "") {
                    setError("Name is blank !");
                    setisNameValid(false);
                } else if (nameValidation(e.target.value) === false) {
                    setisNameValid(false);
                    setError("Wrong Name pattern.!");
                } else {
                    setisNameValid(true);
                }
                break;

            case "phone":
                setError("");
                setMobile(e.target.value);
                if (e.target.value === "") {
                    setError("Phone No. is blank !");
                    setIsMobileValid(false)

                } else if (mobileValidation(e.target.value) == false) {
                    setIsMobileValid(false)
                    setError("Wrong mobile pattern. !");

                } else {
                    setIsMobileValid(true);
                }
                break;
            case "sitename":
                setError("");
                setSiteName(e.target.value);
                setisSiteNameValid(true)
                if (e.target.value === "") {
                    setError("Site name is blank !");
                    setisSiteNameValid(false)
                }
                break;
            case "sitelink":
                setError("");
                setSiteLink(e.target.value);
                setisSiteLinkValid(true)
                if (e.target.value === "") {
                    setError("Site link is blank !");
                    setisSiteLinkValid(false)
                }
                break;
            case "usertype":
                setError("");
                setUserType(e.target.value);
                setIsUserTypeValid(true); //not nessary

                break;

            default:
        }
    }
    const nameValidation = (name) => {

        if (name.match(/[^a-zA-Z ]/)) {

            return false;
        } else {

            setError("");

            return true;
        }

    }


    const mobileValidation = (mobile) => {

        if (mobile.match(/[^0-9]/)) {


            return false;
        } else {

            setError(" ");

            return true;
        }

    }



    const handleSubmit = (e) => {

        e.preventDefault();

        if (isNameValid === false || fullName === null || fullName === '') {
            setError("Something wrong with name value !");
        } else if (mobile === '' || mobile === null || mobile == 0 || mobile.length < 10) {
            setError("Something wrong with phone value !");
        } else if (isSiteNameValid === false || sitename === '' || sitename === null) {
            setError("Something wrong with site name value !");
        } else if (isSiteLinkValid === false || sitelink === '' || sitelink === null) {
            setError("Something wrong with site link value !");
        } else if (usertype === "" || usertype === null) {
            setError("Person Type is not selected !");
        } else {

            handleSubmitCalling();
        }

    }

    const handleSubmitCalling = () => {
        const obj = {
            userid: userid,
            username: fullName,
            sitename: sitename,
            sitelink: sitelink,
            userphone: mobile,
            persontype: usertype,

        };

        Axios.post(url+"update_profile_data.php", obj).then((response) => {

            if (response.data[0].identify == "success") {

                setSuccessMsg(response.data[1].msg);
            } else {
                setErrortMsg(response.data[1].msg);
            }


        });
    }
    const submitMessage = (e) => {
        e.preventDefault();
        if (msgTitile === null || msgTitile === '') {
            setError("Title can not be empty !");
        } else if (msgBody === null || msgBody === ''){
            setError("Message can not be empty !");
        }else{
            setError("");
            const obj = {
                userid: userId,
                receiveuserid: userid,
                messagetitle: msgTitile,
                message: msgBody
            }
    
            Axios.post(url+"sumbit_ad_message.php", obj).then((response) => {
                if (response.data[0].identify == 'success') {
                    setSuccessMsg(response.data[1].msg);
                   
                    setErrortMsg("");
                    clearTextFieldAfterSubmit();
                } else {
    
                    setErrortMsg(response.data[1].msg);
                    setSuccessMsg('');
    
                }
    
    
            });
        }
       
    }
    const clearTextFieldAfterSubmit = () => {
        document.getElementById('title').value = "";
        document.getElementById('msg').value = "";

        setMsgTitle("");
        setMsgBody("");
    }

    if (!open) return null;

    return (
        <div className="single-profile-page-wrapper" style={{ marginTop: "50px" }}>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-left">
                        <div className="col">
                            <h3 className="page-title">Edit Profile</h3>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="form-title"><span>Profile Information</span></h5>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>Full Name</label>
                                                <input type="text" className="form-control" name="name" value={fullName} onChange={(e) => handleInput(e, "name")} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>Mobile</label>
                                                <input type="text" className="form-control" name="phone" value={mobile} onChange={(e) => handleInput(e, "phone")} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>Site Name</label>
                                                <input type="text" className="form-control" name="sitename" value={sitename} onChange={(e) => handleInput(e, "sitename")} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>Site Link</label>
                                                <div>
                                                    <input type="text" className="form-control" name="sitelink" value={sitelink} onChange={(e) => handleInput(e, "sitelink")} />
                                                    <a href={sitelink} target="_blank">Show</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>User Type</label>

                                                <select className="form-control" name="usertype" onChange={(e) => handleInput(e, "usertype")} value={usertype}>
                                                    <option value="head" >Head</option>
                                                    <option value="admin" >Admin</option>
                                                    <option value="user" >User</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>Date</label>
                                                <div>
                                                    <input type="text" className="form-control" value={regdate} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>Exp Date</label>
                                                <input type="text" className="form-control" value={expdate} readOnly />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group">
                                                <label>Validity</label>
                                                <input type="text" className="form-control" value={valid == 0 ? "Not Valid" : "Valid"} readOnly />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-info" style={{ float: "right" }} onClick={(e) => handleSubmit(e)}>Submit</button>
                                            
                                        </div>

                                        <h5 className="form-title" style={{ marginTop: "20px" }}><span>Message</span></h5>

                                        <div className="col-12" style={{ marginTop: "5px" }}>

                                            <div className="form-group">
                                                <label>Title</label>
                                                <input type="text" autoComplete="off" maxLength="250" className="form-control" id="title" onChange={(e) => setMsgTitle(e.target.value)} />
                                                <span>Max length: 250 letters</span>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <textarea rows="5" autoComplete="off" maxLength="500" cols="5" className="form-control" id="msg" placeholder="Enter message" onChange={(e) => setMsgBody(e.target.value)} />
                                                <span>Max length :500 letters</span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-info" style={{ float: "right" }} onClick={(e) => submitMessage(e)}>Submit</button>
                                            <div style={{ alignItems: "center" }}>
                                                {
                                                    <span style={{ color: "Green", alignContent: "center" }}>{successmsg}</span>
                                                }
                                            </div>
                                            <div style={{ alignItems: "center" }}>
                                                {
                                                    <span style={{ color: "Red", alignContent: "center" }}>{errormsg}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SingleProfile;