import Axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import validator from 'validator';
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import { RotatingLines, ColorRing } from "react-loader-spinner";


const SignUp = () => {
    const [email, setEmail] = useState("");
    const [emailCode, setEmailCode] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [sitename, setSiteName] = useState("");
    const [sitelink, setSiteLink] = useState("");
    const [personType, setPersonType] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [isNameValid, setisNameValid] = useState(true);
    const [isPasswordValid, setisPasswordValid] = useState(true);
    const [isPasswordValidFromExternalLibry, setIsPasswordValidFromExternalLibry] = useState(false);
    const [isConfirmPasswordValid, setisConfirmPasswordValid] = useState(true);
    const [isSiteNameValid, setisSiteNameValid] = useState(true);
    const [isSiteLinkValid, setisSiteLinkValid] = useState(true);
    const [isMbileValid, setIsMobileValid] = useState(false);
    const [isEmailValid, setisEmailValid] = useState(true);
    const [isEmailCodeValid, setisEmailCodeValid] = useState(false);
    const [isEmailCodeVerified, setisEmailCodeVerified] = useState(true);
    const [isCodeAndVerifyDisabled, setIsCodeAndVerifyDisabled] = useState(true);
    const [isEmailSend, setIsEmailSend] = useState(true);


    const [successmsg, setSuccessMsg] = useState("");
    const [errormsg, setErrorMsg] = useState("");



    // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1 = "https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;
    // useEffect(() => {

    //     const timer = setTimeout(() => {
    //         setSuccessMsg("");
    //         setError("");
    //         console.log("timeout");
    //     }, 5000);
    //     return () => clearTimeout(timer);

    // }, [])

    const handleInput = (e, type) => {
        switch (type) {
            case "email":
                setError("");
                setEmail(e.target.value);
                if (e.target.value === "") {
                    setError("Email is blank !");
                    setisEmailValid(false);
                } else if (validateEmail(e.target.value) === false) {
                    setisEmailValid(false);
                    setError("Wrong email pattern.!");
                } else {
                    setisEmailValid(true);
                    //after all email verifitation need to disable email field
                }
                break;
            case "code":
                setError("");
                setEmailCode(e.target.value);
                if (e.target.value === "") {
                    setError("Email Code is blank !");
                    setisEmailCodeValid(false);
                } else {
                    setisEmailCodeValid(true);
                    //after all email verifitation need to disable email field
                }
                break;
            case "name":
                setError("");
                setName(e.target.value);
                if (e.target.value === "") {
                    setError("Name is blank !");
                    setisNameValid(false);
                } else if (nameValidation(e.target.value) === false) {
                    setisNameValid(false);
                    setError("Wrong Name pattern.!");
                } else {
                    setisNameValid(true);
                }
                break;

            case "phone":
                setError("");
                setPhone(e.target.value);
                if (e.target.value === "") {
                    setError("Phone No. is blank !");
                    setIsMobileValid(false)

                } else if (mobileValidation(e.target.value) == false) {
                    setIsMobileValid(false)
                    setError("Wrong mobile pattern. !");

                } else {
                    setIsMobileValid(true);
                }
                break;
            case "sitename":
                setError("");
                setSiteName(e.target.value);
                setisSiteNameValid(true)
                if (e.target.value === "") {
                    setError("Site name is blank !");
                    setisSiteNameValid(false)
                }
                break;
            case "sitelink":
                setError("");
                setSiteLink(e.target.value);
                setisSiteLinkValid(true)
                if (e.target.value === "") {
                    setError("Site link is blank !");
                    setisSiteLinkValid(false)
                }
                break;


            default:
        }
    }

    const validateEmail = (email) => {

        if (validator.isEmail(email)) {
            setError("");

            return true;
        } else {

            return false;
        }
    }
    const checkEmail = (e) => {
        e.preventDefault();
        if (isEmailValid === false || email === null || email === '') {
            setError("Something wrong with Email value !");
        } else {
            setIsEmailSend(false);
            const obj = {
                email: email,

            };

            Axios.post(url + "email_check_real_html.php", obj).then((response) => {


                if (response.data[0].identify == 'success') {
                    setSuccessMsg(response.data[1].msg);
                    setErrorMsg("");
                    setIsCodeAndVerifyDisabled(false);
                    setIsEmailSend(true);
                } else {

                    setErrorMsg(response.data[1].msg);
                    setSuccessMsg('');
                    setIsEmailSend(true);

                }

            });
        }

    }

    const verifyEmail = (e) => {
        e.preventDefault();
        if (isEmailCodeValid === false || emailCode === null || emailCode === '') {
            setError("Something wrong with Email Code value !");
        } else {
            const obj = {
                code: emailCode,
                email: email,

            };

            Axios.post(url + "email_verify.php", obj).then((response) => {


                if (response.data[0].identify == 'success') {
                    setSuccessMsg(response.data[1].msg);
                    setErrorMsg("");
                    setisEmailCodeVerified(false);
                    // clearTextFieldAfterSubmit();
                    setIsCodeAndVerifyDisabled(true);
                } else {

                    setErrorMsg(response.data[1].msg);
                    setSuccessMsg('');

                }

            });
        }

    }
    const nameValidation = (name) => {
        if (name.match(/[^a-zA-Z ]/)) {
            return false;
        } else {
            setError("");
            return true;
        }

    }



    const mobileValidation = (mobile) => {

        if (mobile.match(/[^0-9]/)) {


            return false;
        } else {

            setError(" ");

            return true;
        }

    }

    const clearTextFieldAfterSubmit = () => {
        document.getElementById('emal').value = "";
        document.getElementById('emailcode').value = "";
        document.getElementById('uname').value = "";
        document.getElementById('uphone').value = "";
        document.getElementById('sitelink').value = "";
        document.getElementById('sitename').value = "";
        document.getElementById('upassword').value = "";
        document.getElementById('uconfirmpassword').value = "";


        setName("");
        setEmail("");
        setEmailCode("");
        setPhone("");
        setSiteName("");
        setSiteLink("");
        setPassword("");
        setConfirmPassword("");
        setError("");

        setisNameValid(true);
        setisEmailValid(true);
        setisEmailCodeValid(false);
        setIsMobileValid(false);
        setisSiteNameValid(true);
        setisSiteLinkValid(true);
        setisPasswordValid(true);
        setisConfirmPasswordValid(true);

    }


    const handleSubmit = (e) => {
        setSuccessMsg("");
        e.preventDefault();
        if (isEmailValid === false || email === null || email === '') {

            setError("Something wrong with Email value !");
        } else if (isNameValid === false || name === null || name === '') {
            setError("Something wrong with name value !");
        } else if (phone === '' || phone === null || phone == 0 || phone.length !== 10) {
            setError("Something wrong with phone value !");
        } else if (isSiteNameValid === false || sitename === '' || sitename === null) {
            setError("Something wrong with site name value !");
        } else if (isSiteLinkValid === false || sitelink === '' || sitelink === null) {
            setError("Something wrong with site link value !");
        } else if (isPasswordValid === false || password === null || password === '') {
            setError("Something wrong with password value !");
        } else if (personType === "" || personType === null) {
            setError("Person Type is not selected !");
        } else {
            setError("");
            handleSubmitCalling();



        }


    }

    const handleSubmitCalling = () => {
        const obj = {
            email: email,
            username: name,
            sitename: sitename,
            sitelink: sitelink,
            userphone: phone,
            userpassword: password,
            persontype: personType,

        };

        Axios.post(url + "register.php", obj).then((response) => {


            if (response.data[0].identify == 'success') {
                setSuccessMsg(response.data[1].msg);
                setErrorMsg("");

                clearTextFieldAfterSubmit();
            } else {

                setErrorMsg(response.data[1].msg);
                setSuccessMsg('');

            }

        });
    }

    const showPassword = (e) => {
        const passwordInput = document.getElementById("upassword");
        const toggleVisibility = document.getElementById("toggleVisibility");
        if (e.target.checked) {
            passwordInput.type = "text";
        } else {
            passwordInput.type = "password";
        }
    }

    const getValidationValue = (e) => {

        if (e == true) {
            setisPasswordValid(true);

        } else {
            setisPasswordValid(false);

        }
    }
    return (
        <div className='sub_page'>
            <div className="hero_area">

                <section className="contact_section layout_padding" style={{ backgroundColor: "white", paddingTop: "30px" }}>
                    <div className="container">
                        <div className="heading_container" style={{ alignItems: "center" }}>
                            <h2>
                                Register
                            </h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6 offset-md-3" >
                                <div className="form_container contact-form">
                                    {
                                        <span style={{ color: "red" }}>{error}</span>
                                    }
                                    {
                                        <span style={{ color: "Green", alignContent: "center" }}>&nbsp;&nbsp;&nbsp;{successmsg}</span>
                                    }
                                    {
                                        <span style={{ color: "Red", alignContent: "center" }}>{errormsg}</span>
                                    }
                                    <form action="">

                                        <div>
                                            <input type="email" autoComplete="off" placeholder="Email" name="email" id="emal" value={email} onChange={(e) => handleInput(e, "email")} disabled={isEmailCodeVerified ? false : true} />

                                            <button type="submit" className="btn btn-info" onClick={(e) => checkEmail(e)} style={{ width: "100px", height: "30px", padding: "0", float: "left", marginTop: "-9px", marginBottom: "5px", fontSize: "12px" }} disabled={isEmailSend ? false : true}>
                                               
                                                <ColorRing
                                                    visible={!isEmailSend?true:false}
                                                    height="30"
                                                    width="30"
                                                    ariaLabel="color-ring-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="color-ring-wrapper"
                                                    colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}

                                                />Check</button>

                                            <button type="submit" className="btn btn-info" onClick={(e) => verifyEmail(e)} style={{ width: "100px", height: "30px", padding: "0", float: "right", marginTop: "-9px", marginBottom: "5px", fontSize: "12px" }} disabled={isCodeAndVerifyDisabled ? true : false}>Verify</button>
                                            <input type="number" autoComplete="off" name="code" id="emailcode" style={{ width: "100px", height: "30px", padding: "0", float: "right", marginTop: "-9px", marginBottom: "5px", marginRight: "10px", fontSize: "15px", textAlign: 'center' }}
                                                placeholder="Enter code" value={emailCode} onChange={(e) => handleInput(e, "code")} disabled={isCodeAndVerifyDisabled ? true : false} />

                                        </div>
                                        <div>
                                            <input type="number" autoComplete="off" name="phone" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} id="uphone" placeholder="Phone Number" value={phone} onChange={(e) => handleInput(e, "phone")} />

                                        </div>
                                        <div>
                                            <input type="text" placeholder="Full Name" autoComplete="off" name="name" id="uname" value={name} onChange={(e) => handleInput(e, "name")} />

                                        </div>
                                        <div>
                                            <input type="text" placeholder="Site Name" autoComplete="off" name="site_name" id="sitename" value={sitename} onChange={(e) => handleInput(e, "sitename")} />
                                        </div>
                                        <div>
                                            <input type="text" placeholder="Site Link" autoComplete="off" name="site_link" id="sitelink" value={sitelink} onChange={(e) => handleInput(e, "sitelink")} />

                                        </div>
                                        <div>
                                            <input type="password" maxLength="8" autoComplete="off" name="password" id="upassword" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <input type="checkbox" id="toggleVisibility" onChange={(e) => showPassword(e)} style={{ width: "15px", height: "15px", float: "left", marginTop: "-10px", marginRight: "5px" }} /><p style={{ float: "left", marginTop: "-15px" }}>Show Password</p>
                                            <p style={{ float: "right", marginTop: "-15px" }}>Max Length: 8 Characters</p><br />

                                        </div>

                                        <div>
                                            <input type="password" maxLength="8" autoComplete="off" placeholder="Confirm password" name="confirmpassword" id="uconfirmpassword" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                        </div>

                                        <div onChange={(e) => setPersonType(e.target.value)} >
                                            <input type="radio" value="user" name="person" style={{ width: "10%", height: "1em", cursor: "pointer" }} /><span>User</span>
                                            <input type="radio" value="admin" name="person" style={{ width: "10%", height: "1em", cursor: "pointer" }} /><span>Admin</span>
                                            <input type="radio" value="head" name="person" style={{ width: "10%", height: "1em", cursor: "pointer" }} /><span>Super Admin</span>
                                        </div>
                                        <div className="btn_box" >
                                            <button type="button" onClick={(e) => handleSubmit(e)} disabled={isEmailCodeVerified ? true : false} >

                                                Submit
                                            </button>
                                        </div>
                                        <PasswordChecklist
                                            rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                                            minLength={5}
                                            value={password}
                                            valueAgain={confirmpassword}
                                            onChange={(isValid) => getValidationValue(isValid)}
                                        />
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}
export default SignUp;