import React from 'react';
import { MDBDataTable, MDBInput, MDBCheckbox } from 'mdbreact';
import Axios from "axios";
import { useState, useRef, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";

const RegistrationRequestNew = (props) => {

  const [clientsData, setClientsData] = useState([]);
  const [successmsg, setSuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isRegiRequestAvl, setIsRegiRequestAvl] = useState(true);
  const [isAcceptBtnDisable, setIsAcceptBtnDisable] = useState(true);

  const effectRan = useRef(false);
  // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
  // let url1 = "https://millioncliq.com/backend/";

  const url = process.env.REACT_APP_API_URL;


  useEffect(() => {
    if (effectRan.current === false) {
      getClients();
      return () => {
        effectRan.current = true;
      }
    }
  }, []);



  const getClients = async () => {

    await Axios.get(url+"get_register_request.php").then((res) => {

      if (res.data.length > 0) {
        if (res.data[0].identify == 'success') {
          setClientsData(res.data);
          setIsPageLoading(true);
          setIsRegiRequestAvl(false);

          props.handleCallBack(false);
        } else {
          setIsRegiRequestAvl(true);


        }
      } else {
        props.handleCallBack(true);
        setIsRegiRequestAvl(true);
      }

    }).catch((error) => console.log(error));
  }

  const handleChange = (e) => {

    const { id, checked } = e.target;
    if (checked) {
      let tempArray = clientsData.map((client) => client.id == id ? { ...client, valid: 1 } : client);

      setClientsData(tempArray);
      setIsAcceptBtnDisable(false);
    } else {
      let tempArray = clientsData.map((client) => client.id == id ? { ...client, valid: 0 } : client);

      setClientsData(tempArray);
      setIsAcceptBtnDisable(true);
    }
  };


  const handleChangeAll = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      let tempArray = clientsData.map((client) => {
        return { ...client, valid: 1 }
      });
      setClientsData(tempArray);
      setIsAcceptBtnDisable(false);
    } else {
      let tempArray = clientsData.map((client) => {
        return { ...client, valid: 0 }
      });
      setClientsData(tempArray);
      setIsAcceptBtnDisable(true);
    }

  }

  const checkingAnyCheckboxesCheckedorNot = () => {

    for (var x = 0; x < clientsData.length; x++) {
      if (clientsData[x].valid == 1) {
          return true;
      }
  }
  return false;
  }

  const updateClients = (e) => {
    e.preventDefault();
    if (checkingAnyCheckboxesCheckedorNot() == false) {
      setSuccessMsg("")
      setError("Select an item.");
    } else {
      setError("");
      const obj = {
        accepteddata: clientsData
      }

      Axios.post(url+"update_accept_req.php", obj).then((response) => {

        if (response.data[0].identify == 'success') {
          setSuccessMsg(response.data[1].msg);
          setError("");
          const newArray = clientsData.filter((item, index) => item.valid == 0);

          setClientsData(newArray);

          if (newArray.length === 0) {
            setIsAcceptBtnDisable(true);

          } else {
            setIsAcceptBtnDisable(false);
          }

          let checkedallbox = document.getElementById("checkbox5").checked;
          if (checkedallbox == true) {
            document.getElementById("checkbox5").checked = false;
          }

          // clearTextFieldAfterSubmit();
        } else {

          setError(response.data[1].msg);
          setSuccessMsg('');

        }

      });
    }

  }
  const userAttributes = []
  clientsData.forEach(el => {
    userAttributes.push({
      // id: el.id,
      name: el.full_name,
      mobile: el.mobile,
      date: el.date,
      check: <MDBInput type='checkbox' id={el.id.toString()} checked={el?.valid || false} onChange={handleChange} style={{ width: '25px', height: '25px' }} />
    })
  });

  const data = {
    columns: [
      // {
      //   label: 'id',
      //   field: 'id',
      //   sort: 'asc'

      // },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc'
      },
      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc'
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc'
      },
      {
        label: (
          <MDBInput
            label='All'
            type='checkbox'
            id='checkbox5'
            onChange={handleChangeAll}
            style={{ width: '25px', height: '25px', marginLeft: '25px' }} />
        ),
        field: 'check',
        sort: 'disable',

      },


    ],
    rows: userAttributes


  };

  if (isRegiRequestAvl) {
    return null;
  }
  return (

    <div className="col-sm-12" >
      <div className="card card-table" >
        <div className="card-body" >
          <div className="table-responsive">
            {!isPageLoading ? <div style={{ position: 'absolute', top: '70%', left: '50%' }}>
              <RotatingLines strokeColor="skyblue"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={true}

              /></div> :
              <MDBDataTable
                striped
                bordered
                small
                noBottomColumns={true}
                data={data}
                hover={true}

              />
            }
          </div>
        </div>

      </div>
      <div>
        {isPageLoading ? <button type="submit" className="btn btn-info" style={{ marginBottom: "10px", float: 'right', width: '100px' }} onClick={(e) => updateClients(e)} disabled={isAcceptBtnDisable ? true : false}>
          Accept
        </button> : null}

      </div>
      <div style={{ alignItems: "center" }}>
        {
          <span style={{ color: "Green", alignContent: "center" }}>{successmsg}</span>
        }  {
          <span style={{ color: "Red", alignContent: "center" }}>{error}</span>
        }
      </div>
    </div>

  );
}

export default RegistrationRequestNew;