import React from "react";
import { MDBDataTable, MDBInput, MDBCheckbox } from 'mdbreact';
import Axios from "axios";
import { useState, useRef, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";

const RemovePosts=(props)=>{
    const [titleData, setTitleData] = useState([]);
    const [successmsg, setSuccessMsg] = useState("");
    const [error, setError] = useState("");
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isPostDataAvl, setIsPostDataAvl] = useState(false);
    const effectRan = useRef(false);
    // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1 = "https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;

    const [isAcceptBtnDisable, setIsAcceptBtnDisable] = useState(true);

    useEffect(() => {
        if (effectRan.current === false) {
            getTitles();
            return () => {
                effectRan.current = true;
            }
        }
    }, []);


    const getTitles = async () => {

        await Axios.get(url+"get_accepted_post_to_remove.php").then((res) => {
            if (res.data.length > 0) {
                if (res.data[0].identify == 'success') {
                    setTitleData(res.data);
                    setIsPageLoading(true);
                    setIsPostDataAvl(true);

                    props.handleCallBack(false);
                } else {
                    setIsPostDataAvl(false);
                    props.handleCallBack(true);
                }
            } else {
                setIsPostDataAvl(false);
                props.handleCallBack(true);
            }


        }).catch((error) => console.log(error));
    }

    const handleChange = (e) => {

        const { id, checked } = e.target;
        if (checked) {
            let tempArray = titleData.map((title) => title.title_id == id ? { ...title, accept: 1 } : title);

            setTitleData(tempArray);
            setIsAcceptBtnDisable(false);
            // console.log(tempArray);
        } else {
            let tempArray = titleData.map((title) => title.title_id == id ? { ...title, accept: 0 } : title);

            setTitleData(tempArray);
            setIsAcceptBtnDisable(true);
            // console.log(tempArray);
        }
    };

    const handleChangeAll = (e) => {
        const { id, checked } = e.target;
        if (checked) {
            let tempArray = titleData.map((title) => {
                return { ...title, accept: 1 }
            });
            setTitleData(tempArray);
            setIsAcceptBtnDisable(false);
            // console.log(tempArray);
        } else {
            let tempArray = titleData.map((title) => {
                return { ...title, accept: 0 }
            });
            setTitleData(tempArray);
            setIsAcceptBtnDisable(true);
            // console.log(tempArray);
        }

    }
    const userAttributes = []
    titleData.forEach(el => {
        userAttributes.push({
            // id: el.title_id,
            title: (<a href={el.url} target="_blank">{el.title}</a>),
            // url:el.url,
            answers:(<label>{el.ans1},{el.ans2},{el.ans3},{el.ans4},{el.ans5}</label>),
            name: el.full_name,
            mobile: el.mobile,
            sitename: (<a href={el.site_link} target="_blank">{el.site_name}</a>),
            date: el.date,
            check: <MDBInput type='checkbox' id={el.title_id.toString()} checked={el?.accept || false} onChange={handleChange} style={{ width: '25px', height: '25px' }} />
        })
    });



    const data = {
        columns: [
            // {
            //     label: 'Title Id',
            //     field: 'id',
            //     sort: 'asc'

            // },
            {
                label: 'Title',
                field: 'title',
                sort: 'asc'
            },
            {
                label: 'Answers',
                field: 'answers',
                // sort: 'asc'
            },

            {
                label: 'Name',
                field: 'name',
                sort: 'asc'
            },
            {
                label: 'Mobile',
                field: 'mobile',
                sort: 'asc'
            },
            {
                label: 'Site Name',
                field: 'sitename',
                sort: 'asc'
            },
            {
                label: 'Date',
                field: 'date',
                sort: 'asc'
            },
            {
                label: (
                    <MDBInput
                        label='All'
                        type='checkbox'
                        id='checkbox5'
                        onChange={handleChangeAll}

                        style={{ width: '25px', height: '25px', marginLeft: '25px' }} />
                ),
                field: 'check',
                sort: 'disable',

            },


        ],
        rows: userAttributes

    };
    const checkingAnyCheckboxesCheckedorNot = () => {

        for (var x = 0; x < titleData.length; x++) {
            if (titleData[x].accept == 1) {
                return true;
            }
        }
        return false;
    }

    const removePresentation=(e)=>{
        e.preventDefault();

        if (checkingAnyCheckboxesCheckedorNot() == false) {
            setSuccessMsg("");
            setError("Select an item.");
        } else {
            setError("");

            const obj = {
                accepteddata: titleData
            }

            Axios.post(url+"remove_presen_after_accpt.php", obj).then((response) => {

                if (response.data[0].identify == 'success') {
                    setSuccessMsg(response.data[1].msg);
                    setError("");


                    const newArray = titleData.filter((item, index) => item.accept == 0);

                    setTitleData(newArray);

                    if (newArray.length === 0) {
                        setIsAcceptBtnDisable(true);

                    } else {
                        setIsAcceptBtnDisable(false);
                    }

                    let checkedallbox = document.getElementById("checkbox5").checked;
                    if (checkedallbox == true) {
                        document.getElementById("checkbox5").checked = false;
                    }

                    // clearTextFieldAfterSubmit();
                } else {

                    setError(response.data[1].msg);
                    setSuccessMsg('');

                }

            });
        }

    }
    if (!isPostDataAvl) {
        return null;
    }


    return(
        <div className="col-sm-12" >
        <div className="card card-table" >
            <div className="card-body" >
                <div className="table-responsive" >
                    {!isPageLoading ? <div style={{ position: 'absolute', top: '70%', left: '50%' }}>
                        <RotatingLines strokeColor="skyblue"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={true}

                        /></div> :
                        <MDBDataTable
                            striped
                            bordered
                            small
                            noBottomColumns={true}
                            data={data}
                        />
                    }
                </div>
            </div>

        </div>
        <div>
            {/* <button className="btn btn-info" style={{ marginLeft:"10px",marginTop: "10px", float: 'right', width: '100px' }} onClick={(e) => updateTitleRequest(e)} disabled={isAcceptBtnDisable ? true : false}>
                Accept
            </button> */}
            <button className="btn btn-info" style={{ marginTop: "10px", float: 'right', width: '100px' }} onClick={(e) => removePresentation(e)} disabled={isAcceptBtnDisable ? true : false}>
                Remove
            </button>
        </div>
        <div style={{ alignItems: "center" }}>
            {
                <span style={{ color: "Green", alignContent: "center" }}>{successmsg}</span>
            }
        </div>
        <div style={{ alignItems: "center" }}>
            {
                <span style={{ color: "Red", alignContent: "center" }}>{error}</span>
            }
        </div>
    </div>
    )
}
export default RemovePosts;