import React from "react";

import PostPresentation from "./PostPresentation";
import { useEffect, useState, useRef } from 'react';
import { useAuth } from './AuthProvider';
import AnswerQuestions from "./AnswerQuestions";
import Usersummary from "./Usersummary";
import UserCreateMessage from "./UserCreateMessage";
import UserMessageInbox from "./UserMessageInbox";
import Axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";




const UserAccount = () => {

   Axios.defaults.withCredentials = true;
   const [isPostPresantation, setisPostPrenetation] = useState(false);
   const [isAnswerQuestions, setAnswerQuestions] = useState(true);
   // const [isProfile, setisProfile] = useState(false);
   const [isUserSummary, setisUserSummary] = useState(false);
   const [isUserCreateMessage, setisUserCreateMessage] = useState(false);
   const [isMessageInbox, setisMessageInbox] = useState(false);
   const { logout } = useAuth();
   const [isPageLoading, setIsPageLoading] = useState(false);
   const [isAnswerQstAvl, setIsAnswerQstAvl] = useState(false);
   const [isUserSummaryAvl, setIsUserSummaryAvl] = useState(false);
   const [isMsgInboxAvl, setIsMsgInboxAvl] = useState(false);

   const [isDisablePostPresentation, setIsDisablePostPresentation] = useState(true);
   const effectRan = useRef(false);
   const userId = window.localStorage.getItem("userid");
   const username = window.localStorage.getItem("username");
   const navigate = useNavigate();
   // const key = window.localStorage.getItem("user");
  

   const secretKey = window.localStorage.getItem("user");

      const AuthStr =secretKey;
   


   var count = 0;
   var count1 = 0;

   // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
   // let url1 = "https://millioncliq.com/backend/";

   const url = process.env.REACT_APP_API_URL;

   useEffect(() => {
      if (effectRan.current === false) {
         getFinishedPresentationCount();
       
         return () => {
            effectRan.current = true;
         }
      }
   }, []);

   const getFinishedPresentationCount = async () => {
      
      const obj = {
         userid: userId,
      }
      const config = {
         headers: {

             "Content-Type": "application/json",
             "Authorization": AuthStr,
         }
      }
      await Axios.post(url+"get_whole_finished_pre_count.php", obj,config).then((res) => {
         if(res.data[0].identify=='success'){
            if (res.data[0].count >= 30) {
               setIsDisablePostPresentation(false);
   
            } else {
               setIsDisablePostPresentation(true);//need to change to true
            }
            setIsPageLoading(true);
         }else{
            logout();
            navigate("/",{replace:true}); 
            
         }

        
      }).catch((error) => console.log(error));
   }

   const destroySession = () => {
      const config = {
          headers: {
              "Content-Type": "application/json",
              "Authorization": AuthStr,

          }
      };
      Axios.post(url+"session_destroy.php", config).then((res) => {

      });

  }

   const loadingAnswerQuestions = (e) => {
      var element1 = document.getElementById("sub1");
      element1.classList.add("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");
      setisPostPrenetation(false);
      setAnswerQuestions(true);
      responsiveSideBarActive(e);
     
      setIsUserSummaryAvl(false);
      setIsMsgInboxAvl(false);
   }
   const loadingPostPresentation = (e) => {
      var element2 = document.getElementById("sub2");
      element2.classList.add("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");
      setisPostPrenetation(true);
      setAnswerQuestions(false);
      responsiveSideBarActive(e);
      setIsAnswerQstAvl(false);
      setIsUserSummaryAvl(false);
      setIsMsgInboxAvl(false);
   }

   const loadingUserSummary = (e) => {
      var element3 = document.getElementById("sub3");
      element3.classList.add("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");

      setisPostPrenetation(false);
      setAnswerQuestions(false);
      setisUserSummary(true);
      responsiveSideBarActive(e);
      setIsAnswerQstAvl(false);
      setIsMsgInboxAvl(false);
    
   }
   const loadingMessagesInbox = (e) => {
      var element4 = document.getElementById("sub4");
      element4.classList.add("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");

      setisMessageInbox(true);
      setisPostPrenetation(false);
      setAnswerQuestions(false);
      setisUserSummary(false);
      setisUserCreateMessage(false);
      responsiveSideBarActive(e);
      setIsAnswerQstAvl(false);
      setIsUserSummaryAvl(false);
     
   }

   const loadingUserCreateMessage = (e) => {
      var element5 = document.getElementById("sub5");
      element5.classList.add("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      setisPostPrenetation(false);
      setAnswerQuestions(false);
      setisUserSummary(false);
      setisUserCreateMessage(true);
      responsiveSideBarActive(e);
      setIsAnswerQstAvl(false);
      setIsUserSummaryAvl(false);
      setIsMsgInboxAvl(false);
   }

   const responsiveSideBarActive = (e) => {

      count = count + 1;
      if (count % 2 == 1) {

         var element1 = document.getElementById("c");
         element1.classList.add("slide-nav");
      } else {

         var element1 = document.getElementById("c");
         element1.classList.remove("slide-nav");
      }

   }

   const toggleButton = (e) => {

      count1 = count1 + 1;
      if (count1 % 2 == 1) {
         var element1 = document.getElementsByTagName("BODY")[0];
         element1.classList.add("mini-sidebar");
      } else {
         var element1 = document.getElementsByTagName("BODY")[0];
         element1.classList.remove("mini-sidebar");
      }

   }
   const logOut = () => {
      destroySession();
      logout();
   }
   const userSummaryCallBack=(data)=>{
      setIsUserSummaryAvl(data);
   }
   const answerQuizCallBack=(data)=>{
      setIsAnswerQstAvl(data);
   }
   const msgInboxCallBack=(data)=>{
      setIsMsgInboxAvl(data);
   }
   if (!isPageLoading) return (<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(0, -50%)' }} >
      <RotatingLines
         strokeColor="skyblue"
         strokeWidth="5"
         animationDuration="0.75"
         width="50"
         visible={true}

      /></div>);
   return (
      <div>
         <div className="main-wrapper" id="c">
            <div className="header">
               <div className="header-left">
                  <a href="#" className="logo">
                     <img src="images/mqq.png" alt="Logo" />
                  </a>
                  <a href="#" className="logo logo-small">
                     <img src="images/mqq.png" alt="Logo" width="30" height="30" />
                  </a>
               </div>
               <a href="#" id="toggle_btn">
                  <i className="fas fa-align-left" onClick={(e) => toggleButton(e)}></i>
               </a>
               

               <a className="mobile_btn" id="mobile_btn">
                  <i className="fas fa-bars" onClick={(e) => responsiveSideBarActive(e)}></i>
               </a>
               {/* <p style={{ textAlign: "center", marginTop: "15px", color: "blue" }}>Hi {username} ! 
               {isAnswerQstAvl?<span style={{color:"brown"}}>You don't have questions for answer!</span>:isUserSummaryAvl?<span style={{color:"brown"}}>You don't have summary for display!</span>:null}</p> */}
               {/* {isUserSummaryAvl?<span style={{color:"brown"}}>You don't have summary for display!</span>:null}</p> */}

            </div>
            <div className="sidebar" id="sidebar">
               <div className="sidebar-inner slimscroll">
                  <div id="sidebar-menu" className="sidebar-menu">
                     <ul>
                        <li className={isPageLoading ? "submenu active" : "submenu"} id="sub1">
                           <a href="#" onClick={(e) => loadingAnswerQuestions(e)} ><i className="fas fa-user-graduate"></i> <span> Answer Questions</span> </a>

                        </li>
                        <li className="submenu" id="sub2">
                           <a href="#" id="post_pre" style={isDisablePostPresentation ? { pointerEvents: 'none', color: 'brown' } : { pointerEvents: 'auto' }} onClick={(e) => loadingPostPresentation(e)} ><i className="fas fa-sticky-note"></i> <span> Post Presentation </span> </a>

                        </li>

                        <li className="submenu" id="sub3">
                           <a href="#" onClick={(e) => loadingUserSummary(e)}><i className="fas fa-book-open"></i> <span> Summary</span> </a>

                        </li>
                        <li className="submenu" id="sub4">
                           <a href="#" onClick={(e) => loadingMessagesInbox(e)}><i className="fas fa-envelope-open"></i> <span> Message Inbox </span> </a>

                        </li>
                        <li className="submenu" id="sub5">
                           <a href="#" onClick={(e) => loadingUserCreateMessage(e)}><i className="fas fa-solid fa-envelope"></i> <span> Create Message </span> </a>

                        </li>
                        <li className="submenu">
                           <a href="#" onClick={logOut} ><i className="fas fa-sign-out-alt"></i> <span> Log Out</span> </a>

                        </li>

                     </ul>
                  </div>
               </div>
            </div>
            <div className="page-wrapper">
               <div className="content container-fluid" style={{ paddingTop: "0px" }}>
                  <div className="page-header">
                     <div className="row">
                        <div className="col-sm-12" style={{ paddingTop: "0px" }}>
                           <p style={{ textAlign: "center", color: "blue", paddingTop: "7px", paddingBottom: "0px" }}>Hi {username} !
                              {isAnswerQstAvl ? <span style={{color: "brown"}}>&nbsp;&nbsp;You don't have presentations!</span> : null}
                              {isUserSummaryAvl ? <span style={{ color: "brown" }}>&nbsp;&nbsp;You don't have summary to show!</span>:null}
                              {isMsgInboxAvl ? <span style={{ color: "brown" }}>&nbsp;&nbsp;You don't have messages to show!</span>:null}</p>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     {isPostPresantation ? <PostPresentation /> : isAnswerQuestions ? <AnswerQuestions handleCallBack={answerQuizCallBack}/> : isUserSummary ? <Usersummary handleCallBack={userSummaryCallBack}/> : isUserCreateMessage ? <UserCreateMessage /> : isMessageInbox ? <UserMessageInbox handleCallBack={msgInboxCallBack}/> : null}

                  </div>
                  <div className="row">
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default UserAccount;