import React from 'react';
import { useState } from 'react';
import Axios from 'axios';

const UserCreateMessage = () => {
    const [msgTitile, setMsgTitle] = useState("");
    const [msgBody, setMsgBody] = useState("");
    const [error, setError] = useState("");
    const [successmsg, setSuccessMsg] = useState("");
    const [errormsg, setErrortMsg] = useState("");

    const userId = window.localStorage.getItem("userid");

    // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1 = "https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;
    
    const submitMessage = (e) => {
        e.preventDefault();
        if (msgTitile === null || msgTitile === '') {
            setError("Title cannot be empty !");
        } else if (msgBody === null || msgBody === '') {
            setError("Message cannot be empty !");
        } else if (userId === null || userId === "") {
            setError("User id is empty !");
        } else {
            setError("");
            const obj = {
                userid: userId,
                messagetitle: msgTitile,
                message: msgBody
            }

            Axios.post(url+"sumbit_user_message.php", obj).then((response) => {
                
                if (response.data[0].identify == 'success') {

                    setSuccessMsg(response.data[1].msg);

                    setErrortMsg("");
                    clearTextFieldAfterSubmit();
                } else {
                    setErrortMsg(response.data[1].msg);
                    setSuccessMsg('');
                }

            });
        }

    }
    const clearTextFieldAfterSubmit = () => {
        document.getElementById('title').value = "";
        document.getElementById('message').value = "";



        setMsgTitle("");
        setMsgBody("");



    }

    return (
        <div>
            <div className="col-sm-12" >
                <div className="card card-table" >
                    <div className="card-body" >
                        <div className="col-sm-12" >
                            <div className="card card-table" >
                                <div className="card-body" >
                                    <form>
                                        <div className="row">
                                            <div className="col-12">

                                                <h5 className="form-title"><span>Message</span></h5>
                                            </div>
                                            <div className="col-12">
                                                
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input type="text" autoComplete="off" maxLength="200" placeholder="Title" className="form-control" id="title" onChange={(e) => setMsgTitle(e.target.value)} />
                                                    <span>Max length: 200 letters</span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Message</label>
                                                    <textarea rows="10" cols="5" maxLength="500" autoComplete="off" className="form-control" id="message" placeholder="Enter message" onChange={(e) => setMsgBody(e.target.value)} />
                                                    <span>Max length: 500 letters</span>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-info" style={{ float: "right" }} onClick={(e) => submitMessage(e)}>Submit</button>
                                                {
                                                    <span style={{ color: "red" }}>{error}</span>
                                                }
                                                {
                                                    <span style={{ color: "Green", alignContent: "center" }}>{successmsg}</span>
                                                }
                                                {
                                                    <span style={{ color: "Red", alignContent: "center" }}>{errormsg}</span>
                                                }
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </div>
    )
}
export default UserCreateMessage;