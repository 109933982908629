import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEnvelope, faHouse, faPhone, faMapMarker, faMapLocation } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <div>
            <footer className="footer_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 footer_col">
                            <div className="footer_contact">
                                <h4>
                                    {/* Reach at.. */}
                                </h4>
                                <div className="contact_link_box">
                                    {/* <Link to="/contact"> */}
                                    {/* <FontAwesomeIcon icon={faMapMarker}/>&nbsp;&nbsp;&nbsp; */}
                                    {/* <i className="fa fa-map-marker" aria-hidden="true"></i> */}
                                    {/* <span>
                                            Location
                                        </span></Link> */}
                                    {/* <a href="">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        <span>
                                            Location
                                        </span>
                                    </a> */}
                                    {/* <Link to="#"> */}
                                    {/* <FontAwesomeIcon icon={faFacebook} style={{fontSize:"24px"}}/>&nbsp;&nbsp;&nbsp; */}
                                    {/* <i className="fa fa-phone" aria-hidden="true"></i> */}
                                    {/* <span> */}
                                    {/* Call +01 1234567890 */}
                                    {/* </span></Link> */}
                                    {/* <a href="">
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <span>
                                            Call +01 1234567890
                                        </span>
                                    </a> */}
                                    <Link to="#">
                                        <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;&nbsp;
                                        {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                                        <span>

                                            admin@millioncliq.com
                                        </span>
                                        
                                       </Link>
                                       <Link to="#">
                                        <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;&nbsp;
                                        {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                                        <span>

                                            millioncliq@gmail.com
                                        </span>
                                        
                                       </Link>
                                    {/* <a href="">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        <span>
                                            demo@gmail.com
                                        </span>
                                    </a> */}
                                </div>
                            </div>
                            <div className="footer_social">
                                <a href="https://www.facebook.com/profile.php?id=61557158723313&mibextid=ZbWKwL" target="_blank">
                                    <FontAwesomeIcon icon={faFacebook} />

                                </a>
                                {/* <a href="#">
                                <FontAwesomeIcon icon={faTwitter} />
                                
                                </a>
                                <a href="#">
                                <FontAwesomeIcon icon={faLinkedin} />
                                  
                                </a>
                                <a href="#">
                                <FontAwesomeIcon icon={faInstagram} />
                                   
                                </a> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 footer_col">
                            <div className="footer_detail">
                                <h4>
                                    {/* About */}
                                </h4>
                                <p>
                                    {/* Beatae provident nobis mollitia magnam voluptatum, unde dicta facilis minima veniam corporis laudantium alias tenetur eveniet illum reprehenderit fugit a delectus officiis blanditiis ea. */}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 mx-auto footer_col">
                            <div className="footer_link_box">
                                {/* <h4>
                                    Links
                                </h4> */}
                                <div className="footer_links">
                                    {/* <Link to="/" className="active">Home</Link> */}

                                    {/* <Link to="/about" className="">About</Link>
                                  
                                    <Link to="/department" className="">Departments</Link>
                                   
                                     <Link to="/doctors" className="">Doctors</Link> */}

                                    {/* <Link to="/" className="">Contact Us</Link> */}

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </footer>
        </div>
    )
};

export default Footer;