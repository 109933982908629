import React from "react";
import Axios from 'axios';
import { useState, useRef, useEffect } from "react";
import { MDBDataTable, MDBInput, MDBCheckbox } from 'mdbreact';
import { RotatingLines } from "react-loader-spinner";

const Usersummary = (props) => {
    const [presentationList, setPresentationList] = useState([]);
    const [acceptedPreListForCount, setAcceptedPreListForCount] = useState([]);
    const [countList, setCountList] = useState([]);
    const [points, setPoints] = useState(0);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [issummaryAvl, setIsSummaryAvl] = useState(false);

    const userId = window.localStorage.getItem("userid");

    const effectRan = useRef(false);

    // let url="http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1="https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;
    
    useEffect(() => {
        if (effectRan.current === false) {
            getPresentationList();
            getUserPoints();
            return () => {
                effectRan.current = true;
            }
        }
    }, []);

    const getUserPoints = () => {
        const obj = {
            userid: userId,

        };

        Axios.post(url+"get_user_total_points.php", obj).then((response) => {

            
            setPoints(response.data[0].count);
      

        });

    }
    const getPresentationList =async () => {
        const obj = {
            userid: userId,

        };


      await Axios.post(url+"get_presentation_list_for_summary.php", obj).then((response) => {

          
            if (response.data[0].identify == 'success') {
                const acceptedPresentationIds = [];
                response.data.forEach(item => {

                    acceptedPresentationIds.push({
                        id: item.id,
                    });

                });

                setAcceptedPreListForCount(acceptedPresentationIds);

                getFinishedPresantaionCount(acceptedPresentationIds, response.data);

                setIsPageLoading(true);
                setIsSummaryAvl(true);
               
                props.handleCallBack(false);
            } else {
                props.handleCallBack(true);
               

            }

        });
    }


    const getFinishedPresantaionCount = (accepted, prelistarray) => {
        const obj = {
            presantationids: accepted,

        };

        Axios.post(url+"get_finished_presantation_count_uservice.php", obj).then((response) => {
            
            setCountList(response.data);

            for (var i = 0; i < prelistarray.length; i++) {
                for (var j = 0; j < response.data.length; j++) {
                    if (prelistarray[i].id == response.data[j][0].title_id) {
                        prelistarray[i].count = response.data[j][0].count;

                    }
                }
            }

            setPresentationList(prelistarray);
           


        });

    }
    const userAttributes = []
    presentationList.forEach(el => {
        userAttributes.push({
            // id: el.id,
            title: (<a href={el.url}>{el.title}</a>),
            status: (el.accept == 0 ? 'Not Accept' : 'Accept'),
            finished: el.count,
        })
    });

    const data = {
        columns: [
            // {
            //     label: 'id',
            //     field: 'id',
            //     sort: 'asc'

            // },
            {
                label: 'Title',
                field: 'title',
                // sort: 'asc'
            },

            {
                label: 'Status',
                field: 'status',
                // sort: 'asc'
            },
            {
                label: 'Finished Count',
                field: 'finished',
                // width:'150px',
                // sort: 'asc'
            },

        ],
        rows: userAttributes

    };




    if (!issummaryAvl) {
        return null; // Or any other fallback UI if condition is false
      }


    return (
        <div className="col-sm-12" >
            <div className="card card-table" >
                <div className="card-body" >

                    {
                    
                    !isPageLoading ? <div style={{ position: 'absolute', top: '70%', left: '50%' }}>
                        <RotatingLines strokeColor="skyblue"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={true}

                        /></div> :
                        <div>
                            <div style={{ float: "right", paddingBottom: "5px" }}>
                                <p ><span style={{ marginRight: "5px", fontStyle: "italic", color: "brown" }}>Available:</span><span style={{ fontStyle: "italic", color: "brown" }}>{points}/30</span></p>
                                <p ><span style={{ marginRight: "5px", fontStyle: "italic", color: "brown" }}>My Score:</span><span style={{ fontStyle: "italic", color: "brown" }}>{points}</span></p>
                            </div>
                            <div className="table-responsive" >

                                <MDBDataTable
                                    striped
                                    bordered
                                    small
                                    noBottomColumns={true}
                                    data={data}
                                />

                            </div>
                        </div>
                    }
                </div>

            </div>

        </div>
    )
}
export default Usersummary;

