import React from "react";
import Axios from "axios";
import { MDBDataTable, MDBInput, MDBCheckbox } from 'mdbreact';
import { useState, useRef, useEffect } from "react";
import SingleProfile from "./SingleProfile";
import { RotatingLines } from "react-loader-spinner";

const Profile = () => {
    const [profileData, setProfileData] = useState([]);
    const [opentSingleProfile, setOpenSingleProfile] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [isPageLoading, setIsPageLoading] = useState(false);

    const effectRan = useRef(false);
    const userId = window.localStorage.getItem("userid");

    // let url="http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1="https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (effectRan.current === false) {
            getProfileData();

            return () => {
                effectRan.current = true;
            }
        }
    }, []);



    const getProfileData = async () => {

        await Axios.get(url+"get_profile_data.php").then((res) => {
            
            if (res.data[0].identify == 'success') {
                setProfileData(res.data);
                setIsPageLoading(true);

            }

        }).catch((error) => console.log(error));
    }

    const userAttributes = []
    profileData.forEach(el => {
        userAttributes.push({
            // id: el.id,
            name: el.full_name,
            mobile: el.mobile,
            // sitename: (<a href={el.site_link}>{el.site_name}</a>),
            // sitelink: el.site_link,

            usertype: el.user_type,
            // date: el.date,
            // expdate: el.exp_date,
            valid: (el.valid == 0? (<span className="badge badge-danger">Not Valid</span>) : (<span className="badge badge-success">Valid</span>)),
            // valid: (el.valid == 0 ? 'Not Valid' : 'Valid'),
            clickEvent: () => tableRowClick(el.id),
        })
    });

    const data = {
        columns: [
            // {
            //     label: 'id',
            //     field: 'id',
            //     sort: 'asc'

            // },

            {
                label: 'Name',
                field: 'name',
                sort: 'asc'
            },
            {
                label: 'Mobile',
                field: 'mobile',
                sort: 'asc'
            },
            // {
            //     label: 'Site Name',
            //     field: 'sitename',
            //     sort: 'asc'
            // },
            // {
            //     label: 'Site Link',
            //     field: 'sitelink',
            //     sort: 'asc'
            // },
            // {
            //     label: 'Password',
            //     field: 'password',
            //     sort: 'asc'
            // },

            {
                label: 'Type',
                field: 'usertype',
                sort: 'asc',

            },
            // {
            //     label: 'Reg. Date',
            //     field: 'date',
            //     sort: 'asc',

            // },
            // {
            //     label: 'Exp. Date',
            //     field: 'expdate',
            //     sort: 'asc',
            // },
            {
                label: 'Validity',
                field: 'valid',
                sort: 'asc',
            },


        ],
        rows: userAttributes

    };
    const tableRowClick = (e) => {
        setSelectedUserId(e);
        setOpenSingleProfile(true);
    }
    return (
        // <div>
            <div className="col-sm-12">
                <div className="card card-table">
                    <div className="card-body">                     
                        <div className="table-responsive">
                            {!isPageLoading ? <div style={{ position: 'absolute', top: '70%', right: '-850%' }}>
                                <RotatingLines strokeColor="skyblue"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="50"
                                    visible={true}

                                /></div> :
                                <MDBDataTable
                                //    autoWidth={true}
                                    // striped={false}
                                    striped
                                    bordered
                                    small
                                    selectable="true"
                                    noBottomColumns={true}
                                    data={data}
                                    style={{ cursor: "pointer" }}

                                />
                            }
                        </div>
                        
                       
                        <SingleProfile open={opentSingleProfile} userid={selectedUserId} profiledata={profileData} />
                       
                    </div>

                </div>

            </div>

            
        // </div>
    )
};
export default Profile;