import React from "react";
import Axios from 'axios';
import { useState, useRef, useEffect } from "react";

// let url="http://localhost/StdntMg/student-management-sys/public/BackEnd/";
//     let url1="https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;

const Noticeblog = () => {
  const [announcements, setAnnouncements] = useState([]);

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      getAnnouncementDataForNoticeBd();

      return () => {
        effectRan.current = true;
      }
    }
  }, []);

  const getAnnouncementDataForNoticeBd = async () => {

    await Axios.get(url+"get_announcementDataForNoticeBd.php").then((res) => {


      if (res.data[0].identify == 'success') {
        setAnnouncements(res.data);

      }

    }).catch((error) => console.log(error));
  }
  return (

    <div className='sub_page'>
      <div className="hero_area">

        <section className="contact_section layout_padding" style={{ backgroundColor: "white",overflowY:"auto" }}>
          <div className="container">
            <div className="heading_container" style={{ alignItems: "center" }}>
              <h2>
                Notice Board
              </h2>
            </div>
            <div className="row">

              <div id='frame'>
                {
                  announcements.map((item,key) => {
                    if (item.id % 2 == 1) {
                      return (
                        <a className="note sticky1" key={item.id}>
                          <span className='pin'></span>
                          <div className='date-text'>{item.date}</div>
                          <div className='title-text'>{item.title}</div>
                          <div className='body-text'>{item.announcement}</div>
                        </a>)
                    } else {
                      return (
                        <a className="note sticky2" key={item.id}>
                          <span className='pin'></span>
                          <div className='date-text'>{item.date}</div>
                          <div className='title-text'>{item.title}</div>
                          <div className='body-text'>{item.announcement}</div>
                        </a>
                      )
                    }

                  })
                }


              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
export default Noticeblog;