import React from 'react';
import Slider from './Slider';
import Header from './Header';

import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { useMatch } from 'react-router-dom';




const Home = () => {

   const isMatch = useMatch("/:path");


   return (
      <div>
         <div className="hero_area">

            <div className="hero_bg_box">
               <img src="images/tes3.jpg" alt="" />
               {/* <img src="images/Remedy.png" alt="" /> */}
            </div>

            {/* <!-- header section strats --> */}
            <Header />

            {/* <!-- end header section -->
<!-- slider section --> */}



            {isMatch ? <Outlet /> : <Slider />}


            {/* <!-- end slider section --> */}

         </div>
         {/* {!isMatch ? <Department /> : null}
         {!isMatch ? <About /> : null}
         {!isMatch ? <Doctors /> : null}
         {!isMatch ? <Contact /> : null}
         {!isMatch ? <Client /> : null}*/}
                  <Footer /> 

      </div>

   )
};
export default Home;