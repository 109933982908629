import React from "react";
import { MDBDataTable, MDBInput, MDBCheckbox } from 'mdbreact';
import Axios from "axios";
import { useState, useRef, useEffect } from "react";

const Announcements = (props) => {
    const [title, setTitle] = useState("");
    const [announcement, setAnnouncement] = useState("");
    const [successmsg, setSuccessMsg] = useState("");
    const [error, setError] = useState("");
    const [announcemntData, setAnnouncementData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isAnnounceDataAvl, setIsAnnounceDataAvl] = useState(false);
    const [errormsg, setErrorMsg] = useState("");
    const userId = window.localStorage.getItem("userid");
    const effectRan = useRef(false);
    // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    
    // let url1 = "https://millioncliq.com/backend/";
    const url = process.env.REACT_APP_API_URL;
    useEffect(() => {
        if (effectRan.current === false) {
            getAnnouncementData();

            return () => {
                effectRan.current = true;
            }
        }
    }, []);

    const getAnnouncementData = async () => {

        await Axios.get(url+"get_announcementData.php").then((res) => {

            if (res.data[0].identify == 'success') {
                setAnnouncementData(res.data);
                setIsAnnounceDataAvl(true);
                props.handleCallBack(false);
            } else {
                setIsAnnounceDataAvl(false);
                props.handleCallBack(true);
            }

        }).catch((error) => console.log(error));
    }
    const saveAnnouncement = (e) => {
        e.preventDefault();
        if( title === null || title === ''){
            setError("Title can not be empty!");
        }else if(announcement===null || announcement===''){
            setError("Post can not be empty!");
        }else if(userId===null){
            setError("User id is not set!");
        }else{
            setError("")
        const obj = {
            title: title,
            announcement: announcement,
            userid: userId,
        }

        Axios.post(url+"save_announcement.php", obj).then((response) => {


            if (response.data[0].identify == 'success') {
                setSuccessMsg(response.data[1].msg);
               
                setErrorMsg("");
                clearTextFieldAfterSubmit();
            } else {

                setErrorMsg(response.data[1].msg);
                setSuccessMsg('');

            }

        });
    }
    }
    const updateAnnounceVisibility = (e) => {

        e.preventDefault();
        const obj = {
            announceid: e.target.value,
        }

        Axios.post(url+"update_announcement_data.php", obj).then((response) => {



        });

    }
    const clearTextFieldAfterSubmit = () => {
        document.getElementById('title').value = "";
        document.getElementById('announcemnt').value = "";

        setTitle("");
        setAnnouncement("");



    }

    const searchMessages = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (e.target.value !== "") {
            let filteredArray = announcemntData.filter((item) =>

                item.title.includes(query) || item.name.includes(query) || item.date.includes(query)
            );
            setFilteredData(filteredArray);
        } else {
            setFilteredData(announcemntData);
        }
    }

 

    return (
        <div>
            <div className="col-sm-12" >
                <div className="card card-table" >
                    <div className="card-body" >
                        <div className="col-sm-12" >
                            <div className="card card-table" >
                                <div className="card-body" >
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5 className="form-title"><span>Blog Posts</span></h5>
                                            </div>
                                            <div className="col-12">
                                            {
                                                    <span style={{ color: "red" }}>{error}</span>
                                                }
                                                {
                                                    <span style={{ color: "Green", alignContent: "center" }}>{successmsg}</span>
                                                }
                                                {
                                                    <span style={{ color: "Red", alignContent: "center" }}>{errormsg}</span>
                                                }
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input type="text" autoComplete="off" maxLength="200" className="form-control" id="title" onChange={(e) => setTitle(e.target.value)} />
                                                    <span>Max length: 200 letters</span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Post</label>
                                                    <textarea rows="10" autoComplete="off" maxLength="2000" cols="5" className="form-control" id="announcemnt" placeholder="Enter message" onChange={(e) => setAnnouncement(e.target.value)} />
                                                    <span>Max length: 2000 letters</span>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-info" style={{ float: "right" }} onClick={(e) => saveAnnouncement(e)}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                    {
                                        isAnnounceDataAvl ? <div>
                                            <div className="col-12" style={{ marginTop: "5px" }}>
                                                <h5 className="form-title"><span></span></h5>
                                                <div className="form-group">
                                                    <label>Search</label>
                                                    <input type="text" placeholder="Title or Name or Date" className="form-control" id="title" value={searchQuery} onChange={(e) => searchMessages(e)} />
                                                </div>

                                            </div>
                                            <ul className="list-group list-group-light mb-3" style={{ height: '500px', overflowY: 'scroll', marginTop: '5px' }} >
                                                {
                                                    searchQuery.length > 1 ? (filteredData.map((item, key) => {
                                                        return (
                                                            <li className="list-group-item" key={item.id}>
                                                                <span style={{ float: 'right', color: "brown", fontStyle: 'italic' }} hidden={item.visibility == 1 ? false : true}>Removed</span>
                                                                <h5 className="fw-bold">{item.title}</h5><span style={{ float: 'right' }}>{item.date}</span>
                                                                <p className="text-muted mb-2 fw-bold">{item.name}</p>
                                                                <p className="text-muted mb-0">
                                                                    {item.announcement}
                                                                </p>
                                                                <div className="col-sm-12 col-md-12">
                                                                    <button type="submit" style={{ marginTop: "10px", float: 'right', width: '100px' }} value={item.id} onClick={(e) => updateAnnounceVisibility(e)} className="btn btn-info" disabled={item.visibility == 1 ? true : false}>Remove</button>
                                                                </div>
                                                            </li>)
                                                    })) : (
                                                        announcemntData.map((item, key) => {
                                                            return (
                                                                <li className="list-group-item" key={item.id}>
                                                                    <span style={{ float: 'right', color: "brown", fontStyle: 'italic' }} hidden={item.visibility == 1 ? false : true}>Removed</span>
                                                                    <h5 className="fw-bold">{item.title}</h5><span style={{ float: 'right' }}>{item.date}</span>
                                                                    <p className="text-muted mb-2 fw-bold">{item.name}</p>
                                                                    <p className="text-muted mb-0">
                                                                        {item.announcement}
                                                                    </p>
                                                                    <div className="col-sm-12 col-md-12">
                                                                        <button type="submit" style={{ marginTop: "10px", float: 'right', width: '100px' }} value={item.id} onClick={(e) => updateAnnounceVisibility(e)} className="btn btn-info" disabled={item.visibility == 1 ? true : false} >Remove</button>
                                                                    </div>
                                                                </li>)
                                                        })
                                                    )
                                                }

                                            </ul>
                                        </div>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </div>
    )
}
export default Announcements;