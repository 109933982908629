import React from 'react';
import { useState } from 'react';

import Axios from 'axios';
import PasswordChecklist from "react-password-checklist";
import { RotatingLines, ColorRing } from "react-loader-spinner";

const ResetPassword = () => {
    const [userid, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const [modifiedEmail, setModifiedEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mobile, setMobile] = useState("");
    const [isPasswordValid, setisPasswordValid] = useState(true);
    const [isMobileValid, setisMobileValid] = useState(true);
    const [isEmailValid, setisEmailValid] = useState(true);
    const [isUserIdValid, setisUserIdValid] = useState(false);
    const [successmsg, setSuccessMsg] = useState("");
    const [errormsg, setErrorMsg] = useState("");
    const [error, setError] = useState("");
    const [isEmailFound,setIsEmailFound]=useState(true);

    // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1 = "https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;

    const handleInput = (e, type) => {
        switch (type) {

            case "mobile":
                setError("");
                setMobile(e.target.value);
                if (e.target.value === "") {
                    setError("Mobile Number is blank !");
                    setisMobileValid(false);
                } else if (mobileValidation(e.target.value) === false) {
                    setError("Invalid Mobile Number. !");
                    setisMobileValid(false);
                } else {
                    setError("");
                    setisMobileValid(true);
                }

                break;
       
            default:

        }

    }


    const passwordValidation = (password) => {

        if (password.match(/[^a-zA-Z0-9/._@]/)) {
            return false;
        } else {
            setError(" ");
            return true;
        }

    }

    const mobileValidation = (mobile) => {

        if (mobile.match(/[^0-9]/)) {


            return false;
        } else {

            setError(" ");

            return true;
        }

    }

    const searchEmail = (e) => {
        setErrorMsg("");
        setSuccessMsg("");
        e.preventDefault();
        if (isMobileValid === false || mobile === '' || mobile === null) {
            setErrorMsg("Something wrong with moible Number !");
        }
        else {
            setIsEmailFound(false);
            const obj = {
                mobile: mobile,


            };


            Axios.post(url+"search_email_for_reset_pw.php", obj).then((response) => {

              
                if (response.data[0].identify == 'success') {
                    setErrorMsg("");
                    setUserId(response.data[1].id);
                    setEmail(response.data[2].email);
                    setisUserIdValid(true);
                    var searchString = response.data[2].email;
                    let characterToFind = '@';
                    let newSubstring = 'xxxxxx';
                    var indexToReplace = searchString.indexOf(characterToFind);

                    const firsPart = searchString.substring(0, indexToReplace - 6);
                    const secondPart = searchString.substring(indexToReplace, searchString.length);
                    const final = firsPart + newSubstring + secondPart;

                    setModifiedEmail(final);

                    setisEmailValid(true);
                    setIsEmailFound(true);
                } else {
                    setErrorMsg(response.data[1].msg);
                    setisEmailValid(false);
                    setisUserIdValid(false);
                    setIsEmailFound(true);
                }

            });

        }

    }
    const resetPassword = (e) => {
        
        setErrorMsg("");
        setSuccessMsg("");
        e.preventDefault();

        if (isEmailValid === false || email === null || email === '') {
            setErrorMsg("Something wrong with email address !");
        } else if (isPasswordValid === false || password === null || password === '') {
            setErrorMsg("Something wrong with password value !");
        } else if (isUserIdValid === false || userid === null || userid === '') {
            setErrorMsg("Something wrong with user id value !");
        } else {
            const obj = {
                userid: userid,
                upassword: password,
                uemail: email,

            };
            Axios.post(url+"update_reset_password.php",obj).then((response) => {

               
                if (response.data[0].identify == 'success') {
                    setErrorMsg("");
                    setSuccessMsg(response.data[1].msg);

                    clearTextFieldAfterSubmit();


                } else {
                    setErrorMsg(response.data[1].msg);
                    setSuccessMsg("");
                }

            });
        }


    }
    const clearTextFieldAfterSubmit = () => {
        document.getElementById('mobile').value = "";
        document.getElementById('uemail').value = "";
        document.getElementById('upassword').value = "";
        setEmail("");
        setModifiedEmail("");
        setPassword("");
        setMobile("");

    }
    const showPassword = (e) => {
        const passwordInput = document.getElementById("upassword");
        const toggleVisibility = document.getElementById("toggleVisibility");
        if (e.target.checked) {
            passwordInput.type = "text";
        } else {
            passwordInput.type = "password";
        }
    }

    const getVal=(e)=>{
        // console.log(e);
        if(e==true){
            setisPasswordValid(true);
            // setIsPasswordValidFromExternalLibry(true);
        }else{
            setisPasswordValid(false);
            // setIsPasswordValidFromExternalLibry(false);
        }
    }
    return (
        <div className='sub_page'>
            <div className="hero_area">

                <section className="contact_section layout_padding" style={{ backgroundColor: "white" }}>
                    <div className="container">
                        <div className="heading_container" style={{ alignItems: "center" }}>
                            <h2>
                                Reset Password
                            </h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6 offset-md-3" >
                                <div className="form_container contact-form">
                                    <form action="">
                                        <div className="form-row">
                                        {
                                                <span style={{ color: "red" }}>{errormsg}</span>

                                            }
                                            {
                                                <span style={{ color: "green" }}>{successmsg}</span>

                                            }
                                        </div>
                                        <div>
                                            <input type="number" autoComplete="off" className='login_phone' onInput={(e) => e.target.value = e.target.value.slice(0, 10)} placeholder="User name (Phone Number)" name="mobile" id="mobile" value={mobile} onChange={(e) => handleInput(e, "mobile")} />
                                            <button type="submit" className="btn btn-info" onClick={(e) => searchEmail(e)}
                                                style={{ width: "100px", height: "30px", padding: "0", float: "left", marginTop: "-9px", marginBottom: "5px", fontSize: "12px" }}>
                                                    <ColorRing
                                                    visible={!isEmailFound?true:false}
                                                    height="30"
                                                    width="30"
                                                    ariaLabel="color-ring-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="color-ring-wrapper"
                                                    colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}

                                                />Search</button>
                                        </div>
                                        <div>
                                            <input type="email" placeholder="Email" name="email" id="uemail" value={modifiedEmail} disabled />
                                        </div>
                                        <div>
                                            <input type="password" autoComplete="off" placeholder="New Password" name="password" maxLength="8" id="upassword" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <input type="checkbox" id="toggleVisibility" onChange={(e) => showPassword(e)} style={{ width: "15px", height: "15px", float: "left", marginTop: "-10px", marginRight: "5px" }} />
                                            <p style={{ float: "left", marginTop: "-15px" }}>Show Password</p><p style={{ float: "right", marginTop: "-15px" }}>Max Length: 8 Characters</p>
                                        </div>
                                        <div className="btn_box" style={{ paddingBottom: '2%' }}>
                                            <button onClick={(e) => resetPassword(e)}>

                                                Reset
                                            </button>
                                           
                                        </div>
                                        <PasswordChecklist
				                                rules={["minLength","specialChar","number","capital","lowercase"]}
				                                minLength={5}
				                                value={password}
				                                // valueAgain={confirmpassword}
				                                onChange={(isValid) => getVal(isValid)}
			                                    />
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}
export default ResetPassword;