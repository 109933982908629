import React from 'react';
import { MDBDataTable, MDBInput, MDBCheckbox } from 'mdbreact';
import Axios from "axios";
import { useState, useRef, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

const AnswerQuestions = (props) => {

    const [prenetationData, setPresentationData] = useState([]);
    const [answersData, setAnswersData] = useState([]);
    const [presentationids, setPresentationids] = useState([]);
    const [successmsg, setSuccessMsg] = useState("");
    const [error, setError] = useState("");
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const effectRan = useRef(false);
    const iconRef = useRef(null);
    const userId = window.localStorage.getItem("userid");


    
    const [isAns1, setIsAns1] = useState(false);
    const [isAns2, setIsAns2] = useState(false);
    const [isAns3, setIsAns3] = useState(false);
    const [isAns4, setIsAns4] = useState(false);
    const [isAns5, setIsAns5] = useState(false);

    
    
    const url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (effectRan.current === false) {
            getPresentationData();

            return () => {
                effectRan.current = true;
            }
        }
    }, []);



    const getPresentationData = async () => {
        const obj = {
            userid: userId,
        }

        await Axios.post(url + "get_title_for_qz_ansr.php", obj).then((res) => {

            if (res.data[0].length > 0) {
                if (res.data[0][0].identify == 'success') {
                    setPresentationData(res.data[0]);
                    setIsPageLoading(true);

                    props.handleCallBack(false);
                }
            } else {

                props.handleCallBack(true);
            }


        }).catch((error) => console.log(error));
    }

    const handleInput = (e, type) => {

        switch (type) {

            case "ans1":
               
                if (e.target.value !== '') {

                    prenetationData.forEach(element => {
                        if (element.title_id == e.target.id) {
                            if (element.ans1 == e.target.value) {

                                setIsAns1(true);

                            } else {
                                setIsAns1(false);

                            }
                        }
                    });


                }

                break;
            case "ans2":
                
                if (e.target.value !== '') {

                    prenetationData.forEach(element => {
                        if (element.title_id == e.target.id) {
                            if (element.ans2 == e.target.value) {

                                setIsAns2(true);

                            } else {
                                setIsAns2(false);

                            }
                        }
                    });


                }

                break;
            case "ans3":
               
                if (e.target.value !== '') {
                    prenetationData.forEach(element => {
                        if (element.title_id == e.target.id) {
                            if (element.ans3 == e.target.value) {

                                setIsAns3(true);

                            } else {
                                setIsAns3(false);

                            }
                        }
                    });


                }

                break;
            case "ans4":
                
                if (e.target.value !== '') {
                    prenetationData.forEach(element => {
                        if (element.title_id == e.target.id) {
                            if (element.ans4 == e.target.value) {

                                setIsAns4(true);

                            } else {
                                setIsAns4(false);

                            }
                        }
                    });


                }
                break;
            case "ans5":
               
                if (e.target.value !== '') {

                    prenetationData.forEach(element => {
                        if (element.title_id == e.target.id) {
                            if (element.ans5 == e.target.value) {

                                setIsAns5(true);

                            } else {
                                setIsAns5(false);

                            }
                        }
                    });


                }


                break;
            default:
        }
    }


    const submitAnswer = (e) => {
        e.preventDefault();

        if (isAns1 === true && isAns2 === true && isAns3 === true && isAns4 === true && isAns5 === true) {

            const obj = {
                titleid: e.target.value,
                userid: userId,
            }


            Axios.post(url + "post_finished_presentations.php", obj).then((response) => {

                if (response.data[0].identify == 'success') {
                    const newArray = prenetationData.filter((item, index) => item.title_id.toString() !== e.target.value);

                    setPresentationData(newArray);
                    clearVariableValue();

                }

            });
        }else{

        }


    }

    const clearVariableValue = () => {
        setIsAns1(false);
        setIsAns2(false);
        setIsAns3(false);
        setIsAns4(false);
        setIsAns5(false);
        var answerboxes = document.querySelectorAll('.answer_input');
        for (var i = 0; i < answerboxes.length; i++) {

            answerboxes[i].value = "";
        }
    }

    const handleIconClick = (e) => {

        let code = e.currentTarget.getAttribute('data-code');

        navigator.clipboard.writeText(code).then(() => {


        }).catch((e) => {
            console.log('Text copied to clipboard error:', e);
        });

    }


    const userAttributes = []
    prenetationData.forEach(el => {
        userAttributes.push({
            // id: el.title_id,
            url: (
                <div>
                    {(el.count % 2 == 0) ?

                        (<div>
                            {el.title}<br />
                            <Tooltip id="my-tooltip-click" content="Copied!" events={['click']} />
                            <a data-tooltip-id="my-tooltip-click">
                                {el.code} &nbsp;&nbsp;
                                <FontAwesomeIcon
                                    id={el.title_id}
                                    data-code={el.code}
                                    onClick={(e) => handleIconClick(e)}
                                    icon={faCopy}
                                    style={{ cursor: 'pointer', color: 'skyblue' }}
                                />

                            </a>
                        </div>) : (
                            <a href={el.url} id={el.title_id} target="_blank">{el.title}</a>
                        )
                    }

                </div>),

            name: el.full_name,
            // mobile: el.mobile,

            in: (<div><div className="answer_first_div">

                <div>
                    <span className="answer_span" id={el.title_id + 'a'}>
                        <input className="answer_input" maxLength="1" autoComplete="off" type="text" min="1"
                            name={el.ans1} onChange={(e) => handleInput(e, "ans1")} id={el.title_id} />
                    </span>
                </div>

            </div>
                <div className="answer_first_div">

                    <div >
                        <span className="answer_span" id={el.title_id + 'b'}>
                            <input className="answer_input" maxLength="1" autoComplete="off"
                                type="text" name={el.ans2} onChange={(e) => handleInput(e, "ans2")} id={el.title_id} />
                        </span>
                    </div>

                </div>
                <div className="answer_first_div" >

                    <div >
                        <span className="answer_span" id={el.title_id + 'c'}>
                            <input className="answer_input" maxLength="1" autoComplete="off" type="text"
                                name={el.ans3} onChange={(e) => handleInput(e, "ans3")} id={el.title_id} />
                        </span>
                    </div>

                </div>
                <div className="answer_first_div" >

                    <div >
                        <span className="answer_span" id={el.title_id + 'd'}>
                            <input className="answer_input" maxLength="1" autoComplete="off" type="text"
                                name={el.ans4} onChange={(e) => handleInput(e, "ans4")} id={el.title_id} />
                        </span>
                    </div>

                </div>
                <div className="answer_first_div" >

                    <div >
                        <span className="answer_span" id={el.title_id + 'e'}>
                            <input className="answer_input" maxLength="1" autoComplete="off" type="text"
                                name={el.ans5} onChange={(e) => handleInput(e, "ans5")} id={el.title_id} />
                        </span>
                    </div>

                </div>
            </div>),
            button: (<button type="button" className="btn btn-info" value={el.title_id} onClick={(e) => submitAnswer(e)}>submit</button>)
        })
    });

    const data = {
        columns: [
            // {
            //     label: 'id',
            //     field: 'id',
            //     sort: 'asc'

            // },
            {
                label: 'Title',
                field: 'url',
                sort: 'asc'
            },
            {
                label: 'Name',
                field: 'name',
                sort: 'asc'
            },
            // {
            //     label: 'Mobile',
            //     field: 'mobile',
            //     sort: 'asc'
            // },

            {
                label: 'Answers',
                field: 'in',
                sort: 'disable',

            },
            {
                label: ' ',
                field: 'button',
                sort: 'disable',
            }
        ],
        rows: userAttributes

    };

    if (!isPageLoading) {
        return null;
    }
    return (

        <div className="col-sm-12" >
            <div className="card card-table" >
                <div className="card-body" >
                    <div className="table-responsive" >
                        {!isPageLoading ? <div style={{ position: 'absolute', top: '70%', left: '50%' }}>
                            <RotatingLines strokeColor="skyblue"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="50"
                                visible={true}

                            /></div> : <MDBDataTable
                            striped={false}
                            bordered
                            small
                            noBottomColumns={true}
                            data={data}
                            paging={false}


                        />}


                    </div>
                </div>

            </div>

            <div style={{ alignItems: "center" }}>
                {
                    <span style={{ color: "Green", alignContent: "center" }}>{successmsg}</span>
                }
            </div>
        </div>

    );
}

export default AnswerQuestions;