import React from 'react';
import { useState } from 'react';
import validator from 'validator';
import Axios from 'axios';
import { useAuth } from './AuthProvider';
import { Link } from "react-router-dom";



const Login = () => {
    Axios.defaults.withCredentials = true;
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isMobileValid, setisMobileValid] = useState(true);
    const [isPasswordValid, setisPasswordValid] = useState(true);
    const [personType, setPersonType] = useState("");

   
    const { login,userAccountData } = useAuth();
//     let url="http://localhost/StdntMg/student-management-sys/public/BackEnd/";
//    let url1="https://millioncliq.com/backend/";

   const url = process.env.REACT_APP_API_URL;

    const handleInput = (e, type) => {
        switch (type) {

            case "mobile":
                setError("");
                setMobile(e.target.value);
                if (e.target.value === "") {
                    setError("Mobile Number is blank !");
                    setisMobileValid(false);
                } else if (mobileValidation(e.target.value) === false) {
                    setError("Invalid Mobile Number. !");
                    setisMobileValid(false);
                } else {
                    setError("");
                    setisMobileValid(true);
                }

                break;
            case "password":
                setError("");
                setPassword(e.target.value);
                if (e.target.value === "") {
                    setError("Password is blank !");
                    setisPasswordValid(false);
                } else {
                    setError("");
                    setisPasswordValid(true);
                }
                break;
            default:

        }

    }

   
    const mobileValidation = (mobile) => {

        if (password.match(/[^0-9]/)) {


            return false;
        } else{
            setError(" ");

            return true;
        }

    }

 
    const handleSubmit = (e) => {

      
        e.preventDefault();
       
        if (isMobileValid === false || mobile === '' || mobile === null || mobile.length < 10) {
            setError("Something wrong with moible Number !");
        } else if (isPasswordValid === false || password === null || password === '') {
            setError("Something wrong with password value !");
        } 
        else {

            const obj = {
                mobile: mobile,
                userpassword: password,
                
            };
        
                  
            Axios.post(url+"login.php", obj).then((response) => {
          
           
                if (response.data[0].identify == 'success') {                    
                     login(response.data[5].uniqid,response.data[4].usertype);                    
                     userAccountData(response.data[2].id,response.data[3].name);                   
               
                } else {                        
                    setError(response.data[1].msg);
                }

            });

        }
    }
    

    return (
        <div className='sub_page'>
            <div className="hero_area">

                <section className="contact_section layout_padding" style={{ backgroundColor: "white" }}>
                    <div className="container">
                        <div className="heading_container" style={{ alignItems: "center" }}>
                            <h2>
                                Log In
                            </h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6 offset-md-3" >
                                <div className="form_container contact-form">
                                    <form action="">
                                        <div className="form-row">

                                        </div>
                                        <div>
                                            <input type="number" className='login_phone' placeholder="Phone Number" autoComplete="off" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} name="mobile" id="mobile" value={mobile} onChange={(e) => handleInput(e, "mobile")} />
                                        </div>
                                        <div>
                                            <input type="password" placeholder="Password" maxLength="8" name="password" autoComplete="off" id="upassword" value={password} onChange={(e) => handleInput(e, "password")} />
                                        </div>
                                      
                                        <div className="btn_box" style={{ paddingBottom: '28%' }}>
                                            <button onClick={(e)=>handleSubmit(e)}>

                                                Log in
                                            </button>
                                            {
                                                <span style={{ color: "red" }}>{error}</span>
                                            }
                                            <span style={{float:"right"}}><Link to="/resetpassword"> Forgot Password ? </Link></span>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}
export default Login;