import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
var count = 0;
const Header = () => {
    const [isDisplay, setIsDisplay] = useState(false);

    const navbarcollapse = (e) => {
        count = count + 1;
        if (count % 2 == 1) {
            setIsDisplay(true);
        } else {
            setIsDisplay(false);
        }



    }
    return (
        
        <header className="header_section">
            <div className="container">
                <nav className="navbar navbar-expand-lg custom_nav-container ">
                    <Link to="/" className="navbar-brand"><span>
                        <img src="images/mqq.png" alt="" style={{ height: '100%', width: '100%' }} />

                        {/* Orthoc */}
                    </span></Link>


                    <button className="navbar-toggler" id="x" onClick={(e) => navbarcollapse(e)} type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded={!isDisplay ? 'false' : 'true'} aria-label="Toggle navigation">
                        <span className=""> </span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ display: !isDisplay ? 'none' : 'block' }}>
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <Link to="/" className="nav-link" onClick={(e) => navbarcollapse(e)}>Home<span className="sr-only">(current)</span></Link>
                                {/* <a className="nav-link" href="index.html">Home <span className="sr-only">(current)</span></a> */}
                            </li>

                            <li className="nav-item">
                                <Link to="/register" className="nav-link" onClick={(e) => navbarcollapse(e)}>Register</Link>
                                {/* <a className="nav-link" href="contact.html">Contact Us</a> */}
                            </li>
                            <li className="nav-item">
                                <Link to="/login" className="nav-link" onClick={(e) => navbarcollapse(e)}>Log in</Link>

                            </li>
                            <li className="nav-item">
                                <Link to="/blog" className="nav-link" onClick={(e) => navbarcollapse(e)}>Blog</Link>
                            </li>
                            {/* <form className="form-inline">
                                    <button className="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </form> */}
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
       
    )
}
export default Header;