import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children}) => {
   
    const localStoregeValue=window.localStorage.getItem("user");
    const [user, setUser] = useState(localStoregeValue);
    const [userId,setUserId]=useState("");
    const [userName,setUserName]=useState("");
    
    const navigate = useNavigate();

    const login = (data,userType) => {       
            setUser(data);
            window.localStorage.setItem("user", data);      
            if(userType=='user'){
                navigate("/useraccount",{replace:true});
                
            }else{
                navigate("/admin",{replace:true});  
            }       
         
    }
    const userAccountData=(userid,username)=>{
        window.localStorage.setItem("userid",userid ); 
        window.localStorage.setItem("username",username ); 
        setUserId(userid);
        setUserName(username);
        // console.log(username,"username");

    }

    const logout = () => {
        window.localStorage.setItem("user", null);
        window.localStorage.setItem("userid",null); 
        window.localStorage.setItem("username",null);
       
         setUser(null);
        navigate("/", { replace: true });
    }
    

    // const value = useMemo(
    //     () => ({
    //         user,
    //         login,
    //         logout
    //     }),[user]);
    return <AuthContext.Provider value={{user,login,logout,userAccountData,userId,userName}}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};