import React from 'react';
import { Navigate } from 'react-router-dom';
 import { useAuth } from './AuthProvider';
import { AuthProvider } from './AuthProvider';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
  
    if (user=="null" || !user) {
       
         return <Navigate to="/"/>
        
    }
    
    
    return children;

}
export default ProtectedRoute;