import Home from './components/Home';
import WrongUrls from './components/WrongUrls';
import Login from './components/Login';
import SignUp from './components/SignUp';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import UserAccount from './components/UserAccount';
import Admin from './components/Admin';
import Noticeblog from './components/Noticeblog';
import ResetPassword from './components/ResetPassword';

//need to remove



function App() {
      return (
            <div>

                  <Routes>

                        <Route path='/' element={<Home />}>
                              {/* <Route path='about' element={<About />} /> */}
                              {/* <Route path='doctors' element={<Doctors />} /> */}
                              {/* <Route path='department' element={<Department />} /> */}
                              {/* <Route path='contact' element={<Contact />} /> */}
                              <Route path='register' element={<SignUp />} />
                              <Route path='login' element={<Login />} />     
                              <Route path='blog' element={<Noticeblog />} /> 
                              <Route path='resetpassword' element={<ResetPassword />}/>                         
                        </Route>
                       
                        <Route path='/useraccount' element={<ProtectedRoute><UserAccount /></ProtectedRoute>}/> 
                        <Route path='/admin' element={<ProtectedRoute><Admin /></ProtectedRoute>}/> 
                                               
                        {/* <Route path='/useraccount' element={<UserAccount />} /> */}
                        <Route path='/*' element={<WrongUrls />} />

                  </Routes>

            </div>
      );
}

export default App;
