import React from "react";
import { useState } from "react";
import Axios from "axios";
import { RotatingLines, ColorRing } from "react-loader-spinner";


const PostPresentation = () => {
    const [date, setDate] = useState("")
    const [isDate, setisDate] = useState(false)
    const [userid, setUserId] = useState("");
    const [isUserid, setIsUserId] = useState(false);
    const [title, setTitle] = useState("");
    const [code, setCode] = useState("");
    const [isCode, setIsCode] = useState(false);
    const [isTitle, setIsTitle] = useState(false);
    const [url, setUrl] = useState("");
    const [isUrl, setIsUrl] = useState(false);
    const [ans1, setAns1] = useState("");
    const [isAns1, setIsAns1] = useState(false);
    const [ans2, setAns2] = useState("");
    const [isAns2, setIsAns2] = useState(false);
    const [ans3, setAns3] = useState("");
    const [isAns3, setIsAns3] = useState(false);
    const [ans4, setAns4] = useState("");
    const [isAns4, setIsAns4] = useState(false);
    const [ans5, setAns5] = useState("");
    const [isAns5, setIsAns5] = useState(false);
    const [errorDate, setErrorDate] = useState("");
    const [errorTitle, setErrorTitle] = useState("");
    const [errorCode, setErrorCode] = useState("");
    const [errorUrl, setErrorUrl] = useState("");
    const [error1, setError1] = useState("");
    const [error2, setError2] = useState("");
    const [error3, setError3] = useState("");
    const [error4, setError4] = useState("");
    const [error5, setError5] = useState("");
    const [successmsg, setSuccessMsg] = useState("");
    const [answerArray, setAnswerArray] = useState([]);
    const [isSubmitButtonEnble, setIsSubmitButtonEnble] = useState(false);

    const [errormsg, setErrortMsg] = useState("");

    const userId = window.localStorage.getItem("userid");
    // let url2 = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1 = "https://millioncliq.com/backend/";

    const url2 = process.env.REACT_APP_API_URL;

    const handleInput = (e, type) => {

        switch (type) {
            case "date":
                setErrorDate("");
                setDate(e.target.value);
                if (e.target.value === null) {
                    setErrorDate("Date is blank !");
                    setisDate(false);
                } else {
                    setisDate(true);
                }
                break;

            case "title":
                setErrorTitle("");
                setTitle(e.target.value);
                if (e.target.value === "") {
                    setErrorTitle("Title is blank !");
                    setIsTitle(false);

                } else {
                    setIsTitle(true);
                }
                break;
            case "code":
                setErrorCode("");
                setCode(e.target.value);
                if (e.target.value === "") {
                    setErrorCode("Code is blank !");
                    setIsCode(false);

                } else if(codeValidation(e.target.value)==false){
                    setErrorCode("Size must be between 8-15!");
                    setIsCode(false);
                }else {
                    setIsCode(true);
                }
                break;
            case "url":
                setErrorUrl("");
                setUrl(e.target.value);
                setIsUrl(true);
                if (e.target.value === "") {
                    setErrorUrl("Url is blank !");
                    setIsUrl(false);
                }
                break;
            case "ans1":
                setError1("");
                setAns1(e.target.value);

                if (e.target.value === "") {
                    setError1("Answer 1 is blank !");
                    setIsAns1(false);
                } else if (symbolValidation(e.target.value) == false) {
                    setError1("Wrong Answer Type !");
                    setIsAns1(false);
                } else {
                    setIsAns1(true);

                }
                break;
            case "ans2":
                setError2("");
                setAns2(e.target.value);

                if (e.target.value === "") {
                    setError2("Answer 2 is blank !");
                    setIsAns2(false);
                } else if (symbolValidation(e.target.value) == false) {
                    setError2("Wrong Answer Type !");
                    setIsAns2(false);
                } else {
                    setIsAns2(true);

                }
                break;
            case "ans3":
                setError3("");
                setAns3(e.target.value);

                if (e.target.value === "") {
                    setError3("Answer 3 is blank !");
                    setIsAns3(false);
                } else if (symbolValidation(e.target.value) == false) {
                    setError3("Wrong Answer Type !");
                    setIsAns3(false);
                } else {
                    setIsAns3(true);

                }
                break;
            case "ans4":
                setError4("");
                setAns4(e.target.value);

                if (e.target.value === "") {
                    setError4("Answer 4 is blank !");
                    setIsAns4(false);
                } else if (symbolValidation(e.target.value) == false) {
                    setError4("Wrong Answer Type !");
                    setIsAns4(false);
                } else {
                    setIsAns4(true);

                }
                break;
            case "ans5":
                setError5("");
                setAns5(e.target.value);

                if (e.target.value === "") {
                    setError5("Answer 5 is blank !");
                    setIsAns5(false);
                } else if (symbolValidation(e.target.value) == false) {
                    setError5("Wrong Answer Type !");
                    setIsAns5(false);
                } else {
                    setIsAns5(true);

                }
                break;
            default:
        }
    }

    const symbolValidation = (letter) => {

        if (letter.match(/[a-zA-Z0-9]/g)) {
            return true;
        } else {

            return false;
        }

    }

    const codeValidation = (letter) => {

        // console.log(letter.length);
        if (letter.match(/[a-zA-Z0-9]/g) && letter.length>=8) {
            return true;
        } else {

            return false;
        }

    }
    const submitQuizAnsr = (e) => {
        e.preventDefault();
        if (isDate === false || date === null || date === '') {
            setErrorDate("Something wrong with the date value !");
        } else if (isTitle === false || title === null || title === '') {
            setErrorTitle("Something wrong with the title value !");
        } else if (isCode === false || code === null || code === '') {
            setErrorCode("Something wrong with the code value !");
        } else if (isUrl === false || url === null || url === '') {
            setErrorUrl("Something wrong with the url value !");
        } else if (isAns1 === false || ans1 === null || ans1 === '') {
            setError1("Something wrong with the answer 1 value !");
        } else if (isAns2 === false || ans2 === null || ans2 === '') {
            setError2("Something wrong with the answer 2 value !");
        } else if (isAns3 === false || ans3 === null || ans3 === '') {
            setError3("Something wrong with the answer 3 value !");
        } else if (isAns4 === false || ans4 === null || ans4 === '') {
            setError4("Something wrong with the answer 4 value !");
        } else if (isAns5 === false || ans5 === null || ans5 === '') {
            setError5("Something wrong with the answer 5 value !");

        } else {
            setIsSubmitButtonEnble(true);

            const obj = {
                title: title,
                code: code,
                url: url,
                date: date,
                userid: userId,
                ans1: ans1,
                ans2: ans2,
                ans3: ans3,
                ans4: ans4,
                ans5: ans5,

            }

            Axios.post(url2 + "post_presentation.php", obj).then((response) => {


                if (response.data[0].identify == 'success') {
                    // console.log(response);
                    setSuccessMsg(response.data[1].msg);
                    setErrortMsg("");
                    clearTextFieldAfterSubmit();
                    setIsSubmitButtonEnble(false);
                } else {
                    setSuccessMsg("");
                    setErrortMsg(response.data[1].msg);
                    setIsSubmitButtonEnble(false);
                }



            });
        }

    }
    const clearTextFieldAfterSubmit = () => {
        document.getElementById('date').value = "";
        document.getElementById('ans1').value = "";
        document.getElementById('ans2').value = "";
        document.getElementById('ans3').value = "";
        document.getElementById('ans4').value = "";
        document.getElementById('ans5').value = "";
        document.getElementById('title').value = "";
        document.getElementById('url').value = "";
        document.getElementById("code").value = "";

        setDate("");
        setTitle("");
        setUrl("");
        setCode("");
        setAns1("");
        setAns2("");
        setAns3("");
        setAns4("");
        setAns5("");
        // setError("");
        // setSuccessMsg("");
        setIsTitle(false);
        setIsUrl(false);
        setIsCode(false);
        setIsAns1(false);
        setIsAns2(false);
        setIsAns3(false);
        setIsAns4(false);
        setIsAns5(false);
        // setisConfirmPasswordValid(true);

    }
    return (

        <div className="col-sm-12">
            <div className="card">
                <div className="card-body">
                    <form>
                        <div className="row">

                            <div className="col-12 col-sm-3">
                                <div className="form-group">
                                    <label>Date</label>
                                    <input type="date" className="form-control" id="date" value={date} onChange={(e) => handleInput(e, "date")} />
                                    <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{errorDate}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label>Title</label>
                                    <input type="text" autoComplete="off" className="form-control" id="title" value={title} onChange={(e) => handleInput(e, "title")} />
                                    <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{errorTitle}</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-3">
                                <div className="form-group">
                                    <label>Code</label>
                                    <input type="text" autoComplete="off" className="form-control" id="code" value={code} maxLength="15" onChange={(e) => handleInput(e, "code")} />
                                    <span style={{ color: "balck", alignContent: "center", fontSize: '14px' }}>(8-15 Characters)</span><br/>
                                    <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{errorCode}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Url</label>
                                    <input type="text" autoComplete="off" className="form-control" id="url" value={url} onChange={(e) => handleInput(e, "url")} />
                                    <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{errorUrl}</span>
                                </div>
                            </div>
                            <div className="col-sm-1 col-md-2">
                                <p style={{ textAlign: 'center' }}>1</p>
                                <input type="text" autoComplete="off" style={{ textAlign: 'center' }} maxLength="1" className="form-control" id="ans1" value={ans1} onChange={(e) => handleInput(e, "ans1")} />

                                <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{error1}</span>

                            </div>
                            <div className="col-sm-1 col-md-2">
                                <p style={{ textAlign: 'center' }}>2</p>
                                <input type="text" autoComplete="off" style={{ textAlign: 'center' }} maxLength="1" className="form-control" id="ans2" value={ans2} onChange={(e) => handleInput(e, "ans2")} />
                                <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{error2}</span>

                            </div>

                            <div className="col-sm-1 col-md-2">
                                <p style={{ textAlign: 'center' }}>3</p>
                                <input type="text" autoComplete="off" style={{ textAlign: 'center' }} maxLength="1" className="form-control" id="ans3" value={ans3} onChange={(e) => handleInput(e, "ans3")} />
                                <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{error3}</span>

                            </div>

                            <div className="col-sm-1 col-md-2">
                                <p style={{ textAlign: 'center' }}> 4</p>
                                <input type="text" autoComplete="off" style={{ textAlign: 'center' }} maxLength="1" className="form-control" id="ans4" value={ans4} onChange={(e) => handleInput(e, "ans4")} />
                                <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{error4}</span>

                            </div>

                            <div className="col-sm-1 col-md-2">
                                <p style={{ textAlign: 'center' }}>5</p>
                                <input type="text" autoComplete="off" style={{ textAlign: 'center' }} maxLength="1" className="form-control" id="ans5" value={ans5} onChange={(e) => handleInput(e, "ans5")} />
                                <span style={{ color: "Red", alignContent: "center", fontSize: '12px' }}>{error5}</span>

                            </div>

                        </div>

                    </form>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <button type="submit" style={{ marginTop: "10px", float: 'right', width: '100px' }} className="btn btn-info" onClick={(e) => submitQuizAnsr(e)} disabled={isSubmitButtonEnble ? true : false}>
                                <ColorRing
                                    visible={isSubmitButtonEnble ? true : false}
                                    height="22"
                                    width="22"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}

                                />
                                Submit</button>

                        </div>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        {
                            <span style={{ color: "Green", alignContent: "center" }}>{successmsg}</span>
                        }
                    </div>
                    <div style={{ alignItems: "center" }}>
                        {
                            <span style={{ color: "Red", alignContent: "center" }}>{errormsg}</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PostPresentation;