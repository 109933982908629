import React from 'react';
import Axios from 'axios';
import { useState, useRef, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";

const UserMessageInbox = (props) => {


  const [searchQuery, setSearchQuery] = useState('');
  const [msgData, setMsgData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isInboxMsgAvl, setInboxMsgAvl] = useState(false);

  const effectRan = useRef(false);

  // let url="http://localhost/StdntMg/student-management-sys/public/BackEnd/";
  //   let url1="https://millioncliq.com/backend/";

  const url = process.env.REACT_APP_API_URL;
  
  const userId = window.localStorage.getItem("userid");

  useEffect(() => {
    if (effectRan.current === false) {
      getUserMessages();

      return () => {
        effectRan.current = true;
      }
    }
  }, []);

  const getUserMessages = async () => {
    const obj = {
      receiveuserid: userId,
    }
   
    await Axios.post(url+"get_adm_messages.php", obj).then((res) => {
     
      if (res.data[0].identify == 'success') {
        setMsgData(res.data);
        setIsPageLoading(true);
        setInboxMsgAvl(true);
        props.handleCallBack(false);
      }else{
        props.handleCallBack(true);
        
      }

    }).catch((error) => console.log(error));

  };

  const searchMessages = (e) => {

    const query = e.target.value;
    setSearchQuery(query);
    if (e.target.value !== "") {
      let filteredArray = msgData.filter((item) =>
        // return item.msgtitle.indexOf(e.target.value)!==-1;
        item.msgtitle.includes(query) || item.name.includes(query) || item.date.includes(query)
      );
      setFilteredData(filteredArray);
    } else {
      setFilteredData(msgData);
    }
  }

  if (!isInboxMsgAvl) {
    return null; // Or any other fallback UI if condition is false
  }

  return (
    <div className="col-sm-12" >
      <div className="card card-table" >
        <div className="card-body">
          {!isPageLoading ? <div style={{ position: 'absolute', top: '70%', left: '50%' }}>
            <RotatingLines strokeColor="skyblue"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={true}

            /></div> :



            <div>
              <div className="col-12" style={{ marginTop: "5px" }}>
                <div className="form-group">
                  <label>Search</label>
                  <input type="text" placeholder="Title/Name/Date" className="form-control" id="title" value={searchQuery} onChange={(e) => searchMessages(e)} />
                </div>
              </div>
              <ul className="list-group list-group-light mb-3" style={{ height: '500px', overflowY: 'scroll', marginTop: '5px' }} >
                {
                  searchQuery.length > 1 ? (filteredData.map((item,key) => {
                    return (
                      <li className="list-group-item" key={item.id}>
                        <h5 className="fw-bold">{item.msgtitle}</h5><span style={{ float: 'right' }}>{item.date}</span>
                        <p className="mb-2 fw-bold"  style={{fontStyle:'italic',fontSize:'12px',color:"#c24d12"}} >{item.name}</p>
                        <p className="text-muted mb-0">
                          {item.message}
                        </p>
                      </li>)
                  })) : (
                    msgData.map((item) => {
                      return (
                        <li className="list-group-item" key={item.id}>
                          <h5 className="fw-bold">{item.msgtitle}</h5><span style={{ float: 'right' }}>{item.date}</span>
                          <p className="mb-2 fw-bold" style={{fontStyle:'italic',fontSize:'12px',color:"#c24d12"}}>{item.name}</p>
                          <p className="text-muted mb-0">
                            {item.message}
                          </p>
                        </li>)
                    })
                  )


                }


              </ul>
            </div>
          }
          {/* <a className="btn btn-outline-dark btn-rounded w-100" href="#" role="button" data-mdb-ripple-init data-ripple-color="dark">View all</a> */}

        </div>

      </div>
      <div>

      </div>

    </div>
  )
}
export default UserMessageInbox;