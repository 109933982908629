import React from "react";
import { MDBDataTable, MDBInput, MDBCheckbox } from 'mdbreact';
import Axios from "axios";
import { useState, useRef, useEffect } from "react";

const Editslider = (props) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [successmsg, setSuccessMsg] = useState("");
    const [error, setError] = useState("");
    const [sliderData, setSliderData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isSliderDataAvl, setIsSliderDataAvl] = useState(false);


    const [errormsg, setErrorMsg] = useState("");

    const userId = window.localStorage.getItem("userid");
    const effectRan = useRef(false);

    // let url = "http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1 = "https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (effectRan.current === false) {
            getSliderData();

            return () => {
                effectRan.current = true;
            }
        }
    }, []);

    const getSliderData = async () => {

        await Axios.get(url+"get_slider_data.php").then((res) => {

            if (res.data[0].identify == 'success') {
                setSliderData(res.data);
                setIsSliderDataAvl(true);
                props.handleCallBack(false);
            } else {
                setIsSliderDataAvl(false);
                props.handleCallBack(true);
            }

        }).catch((error) => console.log(error));
    }

    const saveSlider = (e) => {
        e.preventDefault();
        if (title === null || title === '') {
            setError("Title can not be empty!");
        } else if (description === null || description === '') {
            setError("Description can not be empty!");
        } else if (userId === null) {
            setError("User id is not set!");
        } else {
            setError("")
            const obj = {
                title: title,
                description: description,
                userid: userId,
            }

            Axios.post(url+"save_slider_data.php", obj).then((response) => {


                if (response.data[0].identify == 'success') {
                    setSuccessMsg(response.data[1].msg);
                    setErrorMsg("");

                    clearTextFieldAfterSubmit();
                } else {

                    setErrorMsg(response.data[1].msg);
                    setSuccessMsg('');

                }

            });
        }


    }
    const updateSliderDataVisibility = (e) => {

        e.preventDefault();
        const obj = {
            sliderid: e.target.value,
        }


        Axios.post(url+"update_slider.php", obj).then((response) => {



        });

    }
    const clearTextFieldAfterSubmit = () => {
        document.getElementById('title').value = "";
        document.getElementById('announcemnt').value = "";



        setTitle("");
        setDescription("");


    }

    const searchMessages = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (e.target.value !== "") {
            let filteredArray = sliderData.filter((item) =>
                // return item.msgtitle.indexOf(e.target.value)!==-1;
                item.title.includes(query) || item.name.includes(query) || item.date.includes(query)
            );
            setFilteredData(filteredArray);
        } else {
            setFilteredData(sliderData);
        }
    }

    

    return (
        <div>
            <div className="col-sm-12" >
                <div className="card card-table" >
                    <div className="card-body" >
                        <div className="col-sm-12" >
                            <div className="card card-table" >
                                <div className="card-body" >
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5 className="form-title"><span>Slider Post</span></h5>
                                            </div>
                                            <div className="col-12">
                                                {
                                                    <span style={{ color: "red" }}>{error}</span>
                                                }
                                                {
                                                    <span style={{ color: "Green", alignContent: "center" }}>{successmsg}</span>
                                                }
                                                {
                                                    <span style={{ color: "Red", alignContent: "center" }}>{errormsg}</span>
                                                }
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input type="text" autoComplete="off" maxLength="30" className="form-control" id="title" onChange={(e) => setTitle(e.target.value)} />
                                                    <span>Max length:30 letters</span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <textarea rows="10" maxLength="100" autoComplete="off" cols="5" className="form-control" id="announcemnt" placeholder="Enter message" onChange={(e) => setDescription(e.target.value)} />
                                                    <span>Max length :100 letters</span>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-info" style={{ float: "right" }} onClick={(e) => saveSlider(e)}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                    {isSliderDataAvl ?
                                        <div>
                                            <div className="col-12" style={{ marginTop: "5px" }}>
                                                <h5 className="form-title"><span></span></h5>
                                                <div className="form-group">
                                                    <label>Search</label>
                                                    <input type="text" placeholder="Title or Name or Date" className="form-control" id="title" value={searchQuery} onChange={(e) => searchMessages(e)} />
                                                </div>
                                            </div>
                                            <ul className="list-group list-group-light mb-3" style={{ height: '500px', overflowY: 'scroll', marginTop: '5px' }} >
                                                {
                                                    searchQuery.length > 1 ? (filteredData.map((item, key) => {
                                                        return (
                                                            <li className="list-group-item" key={item.id}>
                                                                <span style={{ float: 'right', color: "brown", fontStyle: 'italic' }} hidden={item.visibility == 1 ? false : true}>Removed</span>
                                                                <h5 className="fw-bold">{item.title}</h5><span style={{ float: 'right' }}>{item.date}</span>
                                                                {/* <p className="text-muted mb-2 fw-bold">{item.name}</p> */}
                                                                <p className="text-muted mb-0">
                                                                    {item.description}
                                                                </p>
                                                                <div className="col-sm-12 col-md-12">
                                                                    <button type="submit" style={{ marginTop: "10px", float: 'right', width: '100px' }} value={item.id} onClick={(e) => updateSliderDataVisibility(e)} className="btn btn-info" disabled={item.visibility == 1 ? true : false}>Remove</button>
                                                                </div>
                                                            </li>)
                                                    })) : (
                                                        sliderData.map((item, key) => {
                                                            return (
                                                                <li className="list-group-item" key={item.id}>
                                                                    <span style={{ float: 'right', color: "brown", fontStyle: 'italic' }} hidden={item.visibility == 1 ? false : true}>Removed</span>
                                                                    <h5 className="fw-bold">{item.title}</h5><span style={{ float: 'right' }}>{item.date}</span>
                                                                    <p className="text-muted mb-2 fw-bold">{item.name}</p>
                                                                    <p className="text-muted mb-0">
                                                                        {item.description}
                                                                    </p>
                                                                    <div className="col-sm-12 col-md-12">
                                                                        <button type="submit" style={{ marginTop: "10px", float: 'right', width: '100px' }} value={item.id} onClick={(e) => updateSliderDataVisibility(e)} className="btn btn-info" disabled={item.visibility == 1 ? true : false} >Remove</button>
                                                                    </div>
                                                                </li>)
                                                        })
                                                    )
                                                }

                                            </ul>
                                        </div> : null
                                    }

                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </div>
    )
}
export default Editslider;