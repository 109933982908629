import React from "react";
import PostRequest from "./PostRequest";
import Profile from "./Profile";
import RegistrationRequestNew from "./RegistrationRequestNew";
import { useEffect, useState, useRef } from 'react';
import { useAuth } from './AuthProvider';
import Announcements from "./Announcements";
import AdminMessageInbox from "./AdminMessageInbox";
import { RotatingLines } from "react-loader-spinner";
import Axios from 'axios';
import Editslider from "./Editslider";
import RemovePosts from "./RemovePosts";
import { useNavigate } from "react-router-dom";




const Admin = () => {
   Axios.defaults.withCredentials = true;
   const [isProfile, setisProfile] = useState(true);
   const [isRegistrationRequest, setisRegistrationRequest] = useState(false);
   const [isPostRequest, setisPostRequest] = useState(false);
   const [isRemovePost, setisRemovePost] = useState(false);
   
   const [isAnnouncement, setisAnnouncement] = useState(false);
   const [isMessageInbox, setisMessageInbox] = useState(false);
   const [isEditSlider, setisEditSlider] = useState(false);

   const [isPageLoading, setIsPageLoading] = useState(false);
   const [regRequestCount, setRegRequestCount] = useState(0);
   const [postRequestCount, setPostRequestCount] = useState(0);
   const [isRegReqAvl, setisRegReqAvl] = useState(false);
   const [isPostReqAvl, setisPostReqAvl] = useState(false);
   const [isAdMsgInboxAvl, setIsAdMsgInboxAvl] = useState(false);
   const [isSliderDataAvl, setIsSliderDataAvl] = useState(false);
   const [isAnnounceDataAvl, setIsAnnounceDataAvl] = useState(false);
   const [isRemovePostDataAvl, setIsRemovePostDataAvl] = useState(false);

   const navigate = useNavigate();
   const { logout } = useAuth();
   const effectRan = useRef(false);
   var count = 0;
   var count1 = 0;
   const url = process.env.REACT_APP_API_URL;
   // let url="http://localhost/StdntMg/student-management-sys/public/BackEnd/";
   // let url1="https://millioncliq.com/backend/";

   const username = window.localStorage.getItem("username");
   const secretKey = window.localStorage.getItem("user");
   const AuthStr =secretKey;
   
   useEffect(() => {
      if (effectRan.current === false) {
         getRegistrationRequestCount();
         getPostRequestCount();

         return () => {
            effectRan.current = true;
         }
      }
   }, []);



   const responsiveSideBarActive = (e) => {

      count = count + 1;
      if (count % 2 == 1) {

         var element1 = document.getElementById("c");
         element1.classList.add("slide-nav");
      } else {

         var element1 = document.getElementById("c");
         element1.classList.remove("slide-nav");
      }

   }

   const toggleButton = (e) => {

      count1 = count1 + 1;
      if (count1 % 2 == 1) {

         var element1 = document.getElementsByTagName("BODY")[0];
         element1.classList.add("mini-sidebar");
      } else {

         var element1 = document.getElementsByTagName("BODY")[0];
         element1.classList.remove("mini-sidebar");
      }

   }
   const getRegistrationRequestCount = async () => {

      const config = {
         headers: {

             "Content-Type": "application/json",
             "Authorization": AuthStr,
         }
      }
    
      await Axios.get(url+"get_regi_request_count.php",config).then((res) => {

         if (res.data[0].identify == "success") {

            setRegRequestCount(res.data[0].count);
            setIsPageLoading(true);
            
            // setisRegistrationRequest(true);
            setisProfile(true);
         }else{
            logout();
            navigate("/",{replace:true}); 
         }

      }).catch((error) => console.log(error));
   }

   const destroySession = () => {
      const config = {
          headers: {
              "Content-Type": "application/json",
              "Authorization": AuthStr,

          }
      };
      Axios.post(url+"session_destroy.php", config).then((res) => {

      });

  }
   const getPostRequestCount = async () => {
      
      await Axios.get(url+"get_post_request_count.php").then((res) => {

         if (res.data[0].identify = "success") {
            setPostRequestCount(res.data[0].count);

         }

      }).catch((error) => console.log(error));
   }


   const loadingRegistrationRequest = (e) => {

      var element1 = document.getElementById("sub1");
      element1.classList.add("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");
      var element6 = document.getElementById("sub6");
      element6.classList.remove("active");
      var element8 = document.getElementById("sub8");
      element8.classList.remove("active");

      setisRegistrationRequest(true);
      setisPostRequest(false);
      setisProfile(false)
      setisEditSlider(false);
      responsiveSideBarActive(e);
      setisMessageInbox(false);
    
      setisPostReqAvl(false);
      setIsAdMsgInboxAvl(false);
      setIsSliderDataAvl(false);
      setIsAnnounceDataAvl(false);
      setisRemovePost(false);
   }
   const loadingPostRequest = (e) => {

      var element2 = document.getElementById("sub2");
      element2.classList.add("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");
      var element6 = document.getElementById("sub6");
      element6.classList.remove("active");
      var element8 = document.getElementById("sub8");
      element8.classList.remove("active");

      setisRegistrationRequest(false);
      setisProfile(false)
      setisPostRequest(true);
      setisEditSlider(false);
      responsiveSideBarActive(e);
      setisMessageInbox(false);

      setisRegReqAvl(false);
      setIsAdMsgInboxAvl(false);
      setIsSliderDataAvl(false);
      setIsAnnounceDataAvl(false);
      setisRemovePost(false);
     
   }
   const loadingProfile = (e) => {
      var element3 = document.getElementById("sub3");
      element3.classList.add("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");
      var element6 = document.getElementById("sub6");
      element6.classList.remove("active");
      var element8 = document.getElementById("sub8");
      element8.classList.remove("active");

      setisRegistrationRequest(false);
      setisPostRequest(false);
      setisProfile(true)
      setisEditSlider(false);
      setisMessageInbox(false);
      responsiveSideBarActive(e);
      
      setisRegReqAvl(false);
      setisPostReqAvl(false);
      setIsAdMsgInboxAvl(false);
      setIsSliderDataAvl(false);
      setIsAnnounceDataAvl(false);
      setisRemovePost(false);
   }

   const loadingAnnouncements = (e) => {
      var element5 = document.getElementById("sub5");
      element5.classList.add("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      var element6 = document.getElementById("sub6");
      element6.classList.remove("active");
      var element8 = document.getElementById("sub8");
      element8.classList.remove("active");

      setisRegistrationRequest(false);
      setisPostRequest(false);
      setisProfile(false);
      setisAnnouncement(true);
      setisEditSlider(false);
      setisMessageInbox(false);

      responsiveSideBarActive(e);
      setisRegReqAvl(false);
      setisPostReqAvl(false);
      setIsAdMsgInboxAvl(false);
      setIsSliderDataAvl(false);
      setisRemovePost(false);
   }

   const loadingMessagesInbox = (e) => {
      var element4 = document.getElementById("sub4");
      element4.classList.add("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");
      var element6 = document.getElementById("sub6");
      element6.classList.remove("active");
      var element8 = document.getElementById("sub8");
      element8.classList.remove("active");

      setisMessageInbox(true);
      setisRegistrationRequest(false);
      setisPostRequest(false);
      setisProfile(false);
      setisAnnouncement(false);
      setisEditSlider(false);
      responsiveSideBarActive(e);
      setisRegReqAvl(false);
      setisPostReqAvl(false);
      setIsSliderDataAvl(false);
      setIsAnnounceDataAvl(false);
      setisRemovePost(false);
   }

   const loadingEditSlider = (e) => {
      var element6 = document.getElementById("sub6");
      element6.classList.add("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      var element8 = document.getElementById("sub8");
      element8.classList.remove("active");

      setisRegistrationRequest(false);
      setisPostRequest(false);
      setisProfile(false);
      setisAnnouncement(false);
      setisMessageInbox(false);
      
      setisEditSlider(true);
      responsiveSideBarActive(e);
      setisRegReqAvl(false);
      setisPostReqAvl(false);
      setIsAdMsgInboxAvl(false);
      setIsAnnounceDataAvl(false);
      setisRemovePost(false);
   }

   const loadingRemovePost=(e)=>{
      var element8 = document.getElementById("sub8");
      element8.classList.add("active");
      var element6 = document.getElementById("sub6");
      element6.classList.remove("active");
      var element5 = document.getElementById("sub5");
      element5.classList.remove("active");
      var element4 = document.getElementById("sub4");
      element4.classList.remove("active");
      var element3 = document.getElementById("sub3");
      element3.classList.remove("active");
      var element1 = document.getElementById("sub1");
      element1.classList.remove("active");
      var element2 = document.getElementById("sub2");
      element2.classList.remove("active");
      
      setisRemovePost(true);

      setisRegistrationRequest(false);
      setisPostRequest(false);
      setisProfile(false);
      setisAnnouncement(false);
      setisMessageInbox(false);
      
      setisEditSlider(false);
      responsiveSideBarActive(e);
      setisRegReqAvl(false);
      setisPostReqAvl(false);
      setIsAdMsgInboxAvl(false);
      setIsAnnounceDataAvl(false);
   }
   const regiRequstCallBack=(data)=>{
      setisRegReqAvl(data);
   }
   const postRequstCallBack=(data)=>{
      setisPostReqAvl(data);
   }
   const adminMsgInboxCallBack=(data)=>{
      setIsAdMsgInboxAvl(data);
   }
   const sliderDataCallBack=(data)=>{
      setIsSliderDataAvl(data);
   }
   const announceDataCallBack=(data)=>{
      setIsAnnounceDataAvl(data);
   }
   const removePostCallBack=(data)=>{
     
      setIsRemovePostDataAvl(data);
   }
   const logOut = () => {
      destroySession();
      logout();
   }
   if (!isPageLoading) return (<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(0, -50%)' }} >
      <RotatingLines
         strokeColor="skyblue"
         strokeWidth="5"
         animationDuration="0.75"
         width="50"
         visible={true}

      /></div>);

   return (

      <div className="main-wrapper" id="c" >
         {/* style={{overflowX: 'scroll',overflowY:'scroll'}} */}
         <div className="header">
            <div className="header-left">
               <a href="#" className="logo">
                  <img src="images/mqq.png" alt="Logo" />
               </a>
               <a href="#" className="logo logo-small">
                  <img src="images/mqq.png" alt="Logo" width="100" height="100" />
               </a>
            </div>
            <a href="javascript:void(0);" id="toggle_btn">
               <i className="fas fa-align-left" onClick={(e) => toggleButton(e)}></i>
            </a>

            <div className="top-nav-search">


            </div>
            <a className="mobile_btn" id="mobile_btn">
               <i className="fas fa-bars" onClick={(e) => responsiveSideBarActive(e)}></i>
            </a>
            

         </div>
         <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
               <div id="sidebar-menu" className="sidebar-menu">
                  <ul>
                  <li className={isPageLoading?"submenu active":"submenu"} id="sub3">
                        <a href="#" onClick={(e) => loadingProfile(e)}><i className="fas fa-user"></i> <span> Profiles</span></a>

                     </li>

                     <li className="submenu" id="sub1">
                        <a href="#" onClick={(e) => loadingRegistrationRequest(e)}><i className="fas fa-hourglass-start"></i> <span style={{ float: 'right' }}> Reg. Requests </span>

                           <span style={{ marginLeft: '2%', width: '20px', height: '20px', backgroundColor: '#28a745', borderRadius: '50%' }}>
                              <p style={{ paddingTop: '2px', color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: '10px' }}>{regRequestCount}</p>
                           </span>
                        </a>
                     </li>
                     <li className="submenu" id="sub2">
                        <a href="#" onClick={(e) => loadingPostRequest(e)}><i className="fas fa-bell"></i> <span> Post Requests</span>
                           <span style={{ marginLeft: '2%', width: '20px', height: '20px', backgroundColor: '#28a745', borderRadius: '50%' }}>
                              <p style={{ paddingTop: '2px', color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: '10px' }}>{postRequestCount}</p>
                           </span>
                        </a>

                     </li>
                     <li className="submenu" id="sub8">
                        <a href="#" onClick={(e) => loadingRemovePost(e)}><i className="fas fa-remove"></i> <span>Remove Posts</span>
                          
                        </a>

                     </li>
                   
                    
          
                     <li className="submenu" id="sub4">
                        <a href="#" onClick={(e) => loadingMessagesInbox(e)} ><i className="fas fa-envelope-open"></i> <span> Message Inbox</span>

                        </a>
                     </li>
                     <li className="submenu" id="sub5">
                        <a href="#" onClick={(e) => loadingAnnouncements(e)}><i className="fas fa-address-card"></i> <span> Blog Post</span> </a>

                     </li>
                     <li className="submenu" id="sub6">
                        <a href="#" onClick={(e)=>loadingEditSlider(e)}><i className="fas fa-address-card"></i> <span> Edit Slider</span> </a>

                     </li>
                     <li className="submenu" id="sub7">
                        <a href="#" onClick={logOut} ><i className="fas fa-sign-out-alt"></i> <span> Log Out</span> </a>

                     </li>
                  </ul>
               </div>
            </div>
         </div>

         <div className="page-wrapper">
            <div className="content container-fluid">
               <div className="page-header">
                  <div className="row">
                  <div className="col-sm-12" style={{ paddingTop: "0px" }}>
                  {/* <p style={{ textAlign: "center", marginTop: "15px", color: "blue" }}>Hi {username} !</p> */}
                  <p style={{ textAlign: "center", color: "blue", paddingTop: "7px", paddingBottom: "0px" }}>Hi {username} !
                              {isRegReqAvl ? <span style={{color: "brown"}}>&nbsp;&nbsp;No regi. request to show!</span>:null}
                              {isPostReqAvl?<span style={{color: "brown"}}>&nbsp;&nbsp;No Post request data to show!</span>:null}
                              {isAdMsgInboxAvl?<span style={{color: "brown"}}>&nbsp;&nbsp;No Messages to show!</span>:null}
                              {isSliderDataAvl?<span style={{color: "brown"}}>&nbsp;&nbsp;No Previous slider messages to show!</span>:null}
                              {isAnnounceDataAvl?<span style={{color: "brown"}}>&nbsp;&nbsp;No Previous blog post to show!</span>:null}
                              {isRemovePostDataAvl?<span style={{color: "brown"}}>&nbsp;&nbsp;No post available to remove !</span>:null}
                              </p>
                  </div>
                  </div>
                  <div className="row">
                     {isRegistrationRequest ? <RegistrationRequestNew handleCallBack={regiRequstCallBack} /> : isPostRequest ? <PostRequest handleCallBack={postRequstCallBack}/> : isProfile ? <Profile /> : isAnnouncement ? <Announcements handleCallBack={announceDataCallBack}/> : isMessageInbox ? <AdminMessageInbox handleCallBack={adminMsgInboxCallBack}/> :isEditSlider?<Editslider handleCallBack={sliderDataCallBack}/>:isRemovePost?<RemovePosts handleCallBack={removePostCallBack}/>: null}
                  </div>
               </div>

            </div>

         </div>
      </div>

   )
}
export default Admin;