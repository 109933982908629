import React from 'react';

const WrongUrls =()=>{
    return (
        <div className="wrongurl">
            <p style={{fontSize:"30px",textAlign:"center"}}>Error 404 </p>
            <p style={{fontSize:"20px",textAlign:"center"}}>Can't find the page!</p>
            </div>
       
    )
}
export default WrongUrls;