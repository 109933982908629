import React from 'react';
import Axios from 'axios';
import { useState, useRef, useEffect } from "react";

const Slider = () => {

    const [sliderData, setSliderData] = useState([]);
    const effectRan = useRef(false);
    // let url="http://localhost/StdntMg/student-management-sys/public/BackEnd/";
    // let url1="https://millioncliq.com/backend/";

    const url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (effectRan.current === false) {
            getsliderData();

            return () => {
                effectRan.current = true;
            }
        }
    }, []);

    const getsliderData = async () => {

        await Axios.get(url+"get_slider_data_for_slider.php").then((res) => {

            
            if (res.data[0].identify == 'success') {
                setSliderData(res.data);

            }

        }).catch((error) => console.log(error));
    }

    return (
        <div>
            <section className="slider_section ">
                <div id="customCarousel1" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">

                        {sliderData.map((item,key) => {

                            if (item.id % 2 == 1) {

                                return (
                                    <div className="carousel-item active" key={item.id}>
                                        <div className="container ">
                                            <div className="row">
                                                <div className="col-md-7">

                                                    <div className="detail-box">
                                                        <h1>
                                                            {item.title}
                                                        </h1>
                                                        <p>
                                                            {item.description}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="carousel-item" key={item.id}>
                                        <div className="container ">
                                            <div className="row">
                                                <div className="col-md-7">

                                                    <div className="detail-box">
                                                        <h1>
                                                            {item.title}
                                                        </h1>
                                                        <p>
                                                            {item.description}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            }



                        })}

                    </div>
                    <ol className="carousel-indicators">
                        <li data-target="#customCarousel1" data-slide-to="0" className="active"></li>
                        <li data-target="#customCarousel1" data-slide-to="1"></li>
                        {/* <li data-target="#customCarousel1" data-slide-to="2"></li> */}
                    </ol>
                </div>

            </section>
        </div>
    )
}
export default Slider;